import {
	service
} from './axios.js'


export const getPaipan = function (params) {
	return service.post('/service/bazipaipan/v2', params)
}
//阴阳历转换
export const dateConvert = function (params) {
	return service.post('/service/dateConvert', params)
}

export const testPaipan = function (params) {
	return service.post('/service/bazipaipan/v2', params)
}

export const getNLYearList = function (params) {
	return service.post('/service/getNLYearList', params)
}

export const getShenSha = function (params) {
	return service.post('/service/getShenSha', params)
}

export const getOrders = function (params) {
	return service.post('/order/list', params)
}

// export const getOrders = function () {
// 	let pro = new Promise(function (resolve) {
// 		resolve(myOrderData.orderData)
// 	})
// 	return pro
// }

export const getorderStatus = function (params) {
	return service.post('open/order/status', params)
}
//流月 
export const getliuyue = function (params) {
	return service.post('/service/LiuYueShenSha', params)
}
// export const getParamByNo = function(params) {
// 	return service.post('/service/getParamByNo', params)
// }

// export const getParamByNo = function () {
// 	let pro = new Promise(function (resolve) {
// 		resolve(myOrderData.paramData)
// 	})
// 	return pro
// }

export const getCustomer = function (params) {
	return service.post('/service/getActiveCustPerson', params)
}

export const getNewsList = function (params) {
	return service.post('/serviceWeb/getNewsList', params)
}

export const getLaoHuangLiApi = function (params) {
	return service.post('/serviceWeb/getLaoHuangLiApi', params)
}

export const getNewsColumnList = function (params) {
	return service.post('/serviceWeb/getNewsColumnList', params)
}

export const getNewsDetail = function (params) {
	return service.post('/serviceWeb/getNewsDetail', params)
}

export const getCodeUrl = function (params) {
	return service.post('/wxPay/getCodeUrl', params)
}

//反推接口
export const fanTui = function (params) {
	return service.post('/service/baZiRev', params)
}
//根据年柱获取月柱
export const getyz = function (params) {
	return service.post('/service/getYzByNz', params)
}
//根据日柱获取时柱
export const gethz = function (params) {
	return service.post('/service/getSzByDz', params)
}

export const getHL = function (params) {
	return service.get(`/open/laoHuangli?date=${params}`)
}

export const getCityByProvince = (params) =>{
	return service.get(`/open/cityByProvince?pid=${params}`)
}

// 干支关系提示
export const ganZhiRelationship = (params) =>{
	return service.post('/open/ganZhiRelationship',params)
}

// 推荐书籍id
export const getByName = (params) =>{
	return service.get(`/open/book/getByName?bookName=${params}`)
}

// 推荐书籍
export const getByKeyword = (params,params2) =>{
	return service.get(`/open/book/reference/getByKeyword?bookId=${params}&keyword=${params2}`)
}
// 推荐书籍信息
export const getById = (params) =>{
	return service.get(`/open/book/getById?id=${params}`)
}

// 获取订单信息
export const orderList = (params) =>{
	return service.post('/order/list',params)
}

// 取消订单
export const wxPayCanal = (params) =>{
	return service.post('/wxPay/canal',params)
}
