const routeConfig = {
  excepts: [
    "/",
    "/test", //测试网页
    "/cundangList",
    "/main",
    "/sport",
    "/order",
    "/register",
    "/register/index",
    "/register/register-fail",
    "/register/register-success",
    "/register/login",
    "/register/find-password",
    "/register/forget-password",
    "/register/password-success",
    "/register/bind-email",
    "/person",
    "/person/info",
    "/person/orders",
    "/report",
    "/report/info",
    "/report/result",
    "/news/list",
    "/news/info",
    "/crape/info",
    "/crape/result",
    "/name/info",
    "/name/result",
    "/reverse/info",
    "/reverse/result",
    "/chouqian/guanyin",
    "/chouqian/fozu",
    "/chouqian/yuelao",
    "/chouqian/guandi",
    "/chouqian/huangdaxian",
    "/chouqian/lvzu",
    "/chouqian/mazu",
    "/chouqian/caishen",
    "/chouqian/taishanglaojun",
    "/chouqian/wenshupusa",
    "/qimendunjia/info",
    "/qimendunjia/result",
    "/liuyao/info",
    "/liuyao/result",
    "/meihua/info",
    "/meihua/result",
    "/liuren/info",
    "/liuren/result",
    "/chenggu/info",
    "/chenggu/result",
    "/huangli/info",
    "/huangli/result",
    "/tuiguang",
    "/report/result/bazi",
    "/report/result/xingge",
    "/report/result/caiyun",
    "/report/result/shiye",
    "/report/result/qinggan",
    "/report/result/jiankang",
    "/report/result/liuqing",
    "/report/result/liunina",
    "/bazipaipan",
    "/bazipaipan/info",
    "/bazipaipan/result",
    "/danxiangzhengpi",
    "/danxiang/info",
    "/zhengpi/info",
    "/danxiang/shiyeResult",
    "/danxiang/caiyunResult",
    "/danxiang/hunyinResult",
    "/danxiang/jiankangResult",
    "/danxiang/shougaoResult",
    "/danxiang/hehunResult",
    "/danxiang/qimingResult",
    "/danxiang/zeriResult",
    "/zhifuResult",
    "/jieguoResult",
    "/zhengpi/weiziResult",
    "/zhengpi/dianhuaResult",
    "/bazipaipan/shenshaResult",
    "/bazipaipan/tuijianResult",
    "/geren",
    "/geren/huiyuanResult",
    "/geren/chongzhiResult",
    "/geren/dingdanResult",
    "/guji",
    "/guji/info",
    "/guji/list",
    "/zhishi",
    "/zhishi/info",
    "/mingreng",
    "/mingreng/info",
    "/mingreng/result",
    "/xieyi",
    "/yinghuxieyi",
    "/yinsixieyi",
    "/geren/shezhiResult",
    "/geren/xiaoxiResult",
    "/geren/yuerResult",
    "/geren/tixianResult",
    "/geren/txjieguoResult",
    "/fabu/fabuqiuce",
    "/qiuce/dating",
    "/fabu/fabuSuccess",
    "/qiuce/yincangtishi",
    "/qiuce/luntanpay",
    "/qiuce/info",
    "/geren/czjieguoResult",
    "/geren/WithdrawalError",
    "/danxiang/mingjuResult",
    "/cezi/info",
    "/cezi/result",
    "/shipin/list",
    "/video/info",
    "/liudao/liudaoInfo",
    "/liudao/liudaoResult",
    "/bazi",
    "/bazi/baziInfo",
    "/bazi/baziResult",
    "/bazi/shensha",
    "/bazi/tuijian",
    "/answer",
    "/answerInfo",
    "/answeractive",
    "/answercharts",
    "/taohuareport",
    "/taoHuaResult",
    "/namereport",
    "/nameresult",
    "/gift",
    "/answerindx",
  ],
};

export default routeConfig;
