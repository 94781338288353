export const lunarMonthes = [{
		code: 1,
		name: '正'
	},
	{
		code: 2,
		name: '二'
	},
	{
		code: 3,
		name: '三'
	},
	{
		code: 4,
		name: '四'
	},
	{
		code: 5,
		name: '五'
	},
	{
		code: 6,
		name: '六'
	},
	{
		code: 7,
		name: '七'
	},
	{
		code: 8,
		name: '八'
	},
	{
		code: 9,
		name: '九'
	},
	{
		code: 10,
		name: '十'
	},
	{
		code: 11,
		name: '冬'
	},
	{
		code: 12,
		name: '腊'
	}
]

export const lunarDates = [
	{
		code: 1,
		name: '初一'
	},
	{
		code: 2,
		name: '初二'
	},
	{
		code: 3,
		name: '初三'
	},
	{
		code: 4,
		name: '初四'
	},
	{
		code: 5,
		name: '初五'
	},
	{
		code: 6,
		name: '初六'
	},
	{
		code: 7,
		name: '初七'
	},
	{
		code: 8,
		name: '初八'
	},
	{
		code: 9,
		name: '初九'
	},
	{
		code: 10,
		name: '初十'
	},
	{
		code: 11,
		name: '十一'
	},
	{
		code: 12,
		name: '十二'
	},
	{
		code: 13,
		name: '十三'
	},
	{
		code: 14,
		name: '十四'
	},
	{
		code: 15,
		name: '十五'
	},
	{
		code: 16,
		name: '十六'
	},
	{
		code: 17,
		name: '十七'
	},
	{
		code: 18,
		name: '十八'
	},
	{
		code: 19,
		name: '十九'
	},
	{
		code: 20,
		name: '二十'
	},
	{
		code: 21,
		name: '廿一'
	},
	{
		code: 22,
		name: '廿二'
	},
	{
		code: 23,
		name: '廿三'
	},
	{
		code: 24,
		name: '廿四'
	},
	{
		code: 25,
		name: '廿五'
	},
	{
		code: 26,
		name: '廿六'
	},
	{
		code: 27,
		name: '廿七'
	},
	{
		code: 28,
		name: '廿八'
	},
	{
		code: 29,
		name: '廿九'
	},
	{
		code: 30,
		name: '三十'
	}
]

export const lunarHours = [
	{
		code: 0,
		name: '子时(0点)'
	},
	{
		code: 1,
		name: '丑时(1点)'
	},
	{
		code: 2,
		name: '丑时(2点)'
	},
	{
		code: 3,
		name: '寅时(3点)'
	},
	{
		code: 4,
		name: '寅时(4点)'
	},
	{
		code: 5,
		name: '卯时(5点)'
	},
	{
		code: 6,
		name: '卯时(6点)'
	},
	{
		code: 7,
		name: '辰时(7点)'
	},
	{
		code: 8,
		name: '辰时(8点)'
	},
	{
		code: 9,
		name: '巳时(9点)'
	},
	{
		code: 10,
		name: '巳时(10点)'
	},
	{
		code: 11,
		name: '午时(11点)'
	},
	{
		code: 12,
		name: '午时(12点)'
	},
	{
		code: 13,
		name: '未时(13点)'
	},
	{
		code: 14,
		name: '未时(14点)'
	},
	{
		code: 15,
		name: '申时(15点)'
	},
	{
		code: 16,
		name: '申时(16点)'
	},
	{
		code: 17,
		name: '酉时(17点)'
	},
	{
		code: 18,
		name: '酉时(18点)'
	},
	{
		code: 19,
		name: '戌时(19点)'
	},
	{
		code: 20,
		name: '戌时(20点)'
	},
	{
		code: 21,
		name: '亥时(21点)'
	},
	{
		code: 22,
		name: '亥时(22点)'
	},
	{
		code: 23,
		name: '子时(23点)'
	}
]