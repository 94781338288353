import { service } from "./axios.js";
//保存存档
export const saveRecord = function (params) {
  return service.post("/query/record/save ", params);
};
//删除存档
export const delRecord = function (params) {
  return service.post("/query/record/del", params);
};
//存档列表
export const recordList = function (params) {
  return service.post("/query/record/page", params);
};
