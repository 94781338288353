<template>
	<div class="my-sport">
		<img :src="state.filePath" />
	</div>
</template>

<script>
	import {
		defineComponent,
		reactive
	} from 'vue'
	
	export default defineComponent({
		name: 'my-sport',
		setup() {
			
			const state = reactive({
				filePath: process.env.VUE_APP_FILE_PATH + '/yundong.jpg'
			})
			
			return {
				state: state
			}
		}
	})
</script>

<style>
	.my-sport {
		
	}
	
	.my-sport img {
		width: 90vw;
	}
</style>