<template>
  <div class="my-home messageCard">
    <my-header></my-header>
    <div class="my-middle">
      <div class="content">
        <!-- 上部背景框 -->
        <div class="content_top">
          <div class="swiper">
            <!-- <img class="slide" src="../../../public/images/gouhuo-bg.jpg" alt="" /> -->
            <template v-if="state.xinnianInfo">
              <img class="slide" style="cursor: pointer" src="../../../public/images/命理活动入口.jpg" @click="router.push('/answercharts')" />
            </template>
            <template v-else>
              <!-- <img class="slide" src="../../../public/images/home1.jpg" alt="" /> -->
              <img src="../../../public/images/xinnian.jpg" alt="" />
              <!-- 活动送礼图 -->
              <!-- <img src="../../../public/images/huodonghome.jpg" style="cursor: pointer;" @click="router.push('/gift')" alt="" /> -->
            </template>
            <!-- <template v-for="item in state.adList" :key="item.id">
              <img :src="item.pic" class="" />
            </template> -->
          </div>
        </div>
        <!-- 排盘内容 -->
        <div class="content_paipan" style="position: relative;">
          <my-bazi></my-bazi>
          <my-rightpanels style="position: absolute; top: 30px; right: 34px" @click="goHunagli"></my-rightpanels>
          <div style="display: flex;position: absolute;right: 0;bottom: 10px;align-items: center;">
            <img src="../../../public/images/！.png" alt="" style="width: 20px;height: 20px;">
            <div style="margin-left: 10px;font-size: 14px;color: #999;">平台所有产品拒绝向未成年人提供服务，仅供娱乐和参考。</div>
          </div>
        </div>

        <!-- 商店宣传图 -->
        <div class="content_img">
          <div class="content_img1">
            <div class="content_imgtop" @click="qu('/qiuce/dating')" style="cursor: pointer">
              <img src="../../../public/images/home_img1.png" style="cursor: pointer" />
            </div>
            <div style="line-height: 52px; font-size: 16px; font-weight: 200; cursor: pointer" class="color000">求助大厅，让命理为你解决人生困惑</div>
          </div>
          <div class="content_img2 ml" @click="qu('/mingreng/info')" style="cursor: pointer">
            <div class="content_imgtop">
              <img src="../../../public/images/home_img2.png" style="cursor: pointer" />
            </div>
            <div style="line-height: 52px; font-size: 16px; font-weight: 200" class="color000">跟随名人事迹读懂背后的五行生克制化</div>
          </div>
          <div class="content_img3 ml" @click="shangcheng" style="cursor: pointer">
            <div class="content_imgtop">
              <img src="../../../public/images/home_img3.png" style="width: 388px; height: 197px" />
            </div>
            <div style="line-height: 52px; font-size: 16px; font-weight: 200" class="color000">转运商城，精彩纷呈</div>
          </div>
        </div>
        <div class="pibo">批卜服务</div>
        <div class="pibo_info">
          <h2 style="font-size: 20px; position: relative; top: 55px; font-weight: 400; color: #000">事业、姻缘、财运、健康、起名和择日等批卜服务</h2>
          <div class="pibo_content">
            <p>命理，一般称之为“子平法”, 自汉代兴起，已有近两千年的历史。以阴阳五行为基础，天干地支为依据，从理论到技法，形成了一套完整、复杂的体系，深得民众的认可和喜爱。</p>
            <p>所以我们了解命理的目的，是为了更好的把握好自己。</p>
          </div>
          <div class="pibu_infobtn">
            <div class="pibu_infobtn1" @click="qu('/danxiang/info', index)">命理批卜</div>
            <!-- <div class="pibu_infobtn2" @click="qu('/zhengpi/info',index)" style="margin-left: 13px;">命局整批
						</div> -->
          </div>
        </div>
      </div>

      <!-- 国学讲堂 -->
      <div class="guuoxuejiangtang">
        <div class="guuoxuejiangtang_header">
          <div class="guuoxuejiangtang_title">
            <div class="guoxueclass">国学讲堂</div>
            <div class="theother" @click="goVideoList">更多<img src="../../../public/images/arrow.png" style="margin-bottom: 3px" /></div>
          </div>
        </div>
        <!-- 讲堂视频封面 -->
        <div class="videolist">
          <template v-for="(item, index) in state.videoList" :key="index">
            <div class="videoitem" @click="goVideo(item.id)">
              <div class="videoitem_img" @mouseenter="mouseover(index)" @mouseleave="blur(index)">
                <img :src="item.image" />
                <div class="Mask none">
                  <img src="../../../public/images/zhongzhi.png" alt="" />
                </div>
              </div>
              <div class="videoitem_title">
                <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: calc(100% - 42px); text-align: left">
                  {{ item.title }}
                </div>
                <div class="videoicon" :style="{ background: determineTime(item.utime) == true ? '#ff7c00' : '#22ac38' }">
                  {{ determineTime(item.utime) == true ? item.tag : "最新" }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <!-- 求签工具 -->
      <div class="content1">
        <div class="content1_top">
          <div class="content1_btn active" id="ctbtn" @click="Switchingtools(0)">命理古籍</div>
          <div class="content1_btn" id="ctbtn" @click="Switchingtools(1)">每日一签</div>
          <div class="content1_btn" id="ctbtn" @click="Switchingtools(2)">排盘工具</div>
        </div>
        <!-- 批卜服务 -->
        <div class="content1_info">
          <div class="content1_lunbo">
            <!-- 命理古籍 -->
            <div id="div">
              <template v-for="item in state.bookList" :key="item.id">
                <div class="div_info ml15 mt25" @click="goBook(item)">
                  <div class="div_blue">
                    <p style="font-size: 18px; color: #fff">《{{ item.name }}》</p>
                    <p style="font-size: 14px">{{ item.describe }}</p>
                    <div class="gofanyue">去翻阅</div>
                  </div>
                </div>
              </template>
            </div>

            <div id="div">
              <!-- 每日一签 -->
              <div class="div_info" @click="goitem(`/chouqian/guanyin`)">
                <div class="div_red">
                  <p style="font-size: 18px; color: #fff">观自在签</p>
                  <p style="font-size: 14px">自古至今流传久远</p>
                  <div class="gochouqian">去抽签</div>
                </div>
              </div>
              <div class="div_info ml15" @click="goitem(`/chouqian/guandi`)">
                <div class="div_red">
                  <p style="font-size: 18px; color: #fff">关帝签</p>
                  <p style="font-size: 14px">俗称关公，世人崇拜英雄</p>
                  <div class="gochouqian">去抽签</div>
                </div>
              </div>
              <div class="div_info ml15" @click="goitem(`/chouqian/yuelao`)">
                <div class="div_red">
                  <p style="font-size: 18px; color: #fff">月老签</p>
                  <p style="font-size: 14px">月老成就世间的姻缘</p>
                  <div class="gochouqian">去抽签</div>
                </div>
              </div>
              <div class="div_info ml15" @click="goitem(`/chouqian/fozu`)">
                <div class="div_red">
                  <p style="font-size: 18px; color: #fff">佛祖签</p>
                  <p style="font-size: 14px">佛祖慈悲</p>
                  <div class="gochouqian">去抽签</div>
                </div>
              </div>
              <div class="div_info ml15" @click="goitem(`/chouqian/huangdaxian`)">
                <div class="div_red">
                  <p style="font-size: 18px; color: #fff">黄初平签</p>
                  <p style="font-size: 14px">心诚则灵</p>
                  <div class="gochouqian">去抽签</div>
                </div>
              </div>
              <div class="div_info mt25" @click="goitem(`/chouqian/caishen`)">
                <div class="div_red">
                  <p style="font-size: 18px; color: #fff">财神签</p>
                  <p style="font-size: 14px">吉祥美好</p>
                  <div class="gochouqian">去抽签</div>
                </div>
              </div>
              <div class="div_info ml15 mt25" @click="goitem(`/chouqian/lvzu`)">
                <div class="div_red">
                  <p style="font-size: 18px; color: #fff">吕祖签</p>
                  <p style="font-size: 14px">慧根参透当中的玄机</p>
                  <div class="gochouqian">去抽签</div>
                </div>
              </div>
              <div class="div_info ml15 mt25" @click="goitem(`/chouqian/taishanglaojun`)">
                <div class="div_red">
                  <p style="font-size: 18px; color: #fff">太上老君签</p>
                  <p style="font-size: 14px">混沌之主</p>
                  <div class="gochouqian">去抽签</div>
                </div>
              </div>
              <div class="div_info ml15 mt25" @click="goitem(`/chouqian/mazu`)">
                <div class="div_red">
                  <p style="font-size: 18px; color: #fff">妈祖签</p>
                  <p style="font-size: 14px">消灾减难的女海神</p>
                  <div class="gochouqian">去抽签</div>
                </div>
              </div>
              <div class="div_info ml15 mt25" @click="goitem(`/chouqian/wenshupusa`)">
                <div class="div_red">
                  <p style="font-size: 18px; color: #fff">文殊菩萨签</p>
                  <p style="font-size: 14px">常行善行</p>
                  <div class="gochouqian">去抽签</div>
                </div>
              </div>
            </div>

            <!-- 排盘工具 -->
            <div id="div">
              <div class="div_info">
                <div class="div_red" @click="goitem('/bazipaipan/info')">
                  <p style="font-size: 18px; color: #fff">八字排盘</p>
                  <p style="font-size: 14px">推断寿夭贵贱，祸福休咎</p>
                  <div class="gochouqian">去排盘</div>
                </div>
              </div>
              <div class="div_info ml15" @click="goitem('/crape/info')">
                <div class="div_red">
                  <p style="font-size: 18px; color: #fff">紫微斗数</p>
                  <p style="font-size: 14px">号称"中华第一神数"</p>
                  <div class="gochouqian">去排盘</div>
                </div>
              </div>
              <div class="div_info ml15" @click="goitem('/liuyao/info')">
                <div class="div_red">
                  <p style="font-size: 18px; color: #fff">六爻排盘</p>
                  <p style="font-size: 14px">易占家必学之术</p>
                  <div class="gochouqian">去起卦</div>
                </div>
              </div>
              <div class="div_info ml15" @click="goitem('/liuren/info')">
                <div class="div_red">
                  <p style="font-size: 18px; color: #fff">大六壬排盘</p>
                  <p style="font-size: 14px">万事万物皆可占卜</p>
                  <div class="gochouqian">去起课</div>
                </div>
              </div>
              <div class="div_info ml15" @click="goitem('/meihua/info')">
                <div class="div_red">
                  <p style="font-size: 18px; color: #fff">梅花易数</p>
                  <p style="font-size: 14px">先天卦和后天卦并用</p>
                  <div class="gochouqian">去起卦</div>
                </div>
              </div>
              <div class="div_info mt25" @click="goitem('/qimendunjia/info')">
                <div class="div_red">
                  <p style="font-size: 18px; color: #fff">奇门遁甲</p>
                  <p style="font-size: 14px">第一大秘术，为三式之首</p>
                  <div class="gochouqian">去起局</div>
                </div>
              </div>
              <div class="div_info mt25 ml15" @click="goitem('/chenggu/info')">
                <div class="div_red">
                  <p style="font-size: 18px; color: #fff">袁天罡称骨</p>
                  <p style="font-size: 14px">更容易掌握和运用</p>
                  <div class="gochouqian">去求测</div>
                </div>
              </div>
              <div class="div_info mt25 ml15" @click="goitem('/cezi/info')">
                <div class="div_red">
                  <p style="font-size: 18px; color: #fff">在线测字</p>
                  <p style="font-size: 14px">“三才者，天地人”</p>
                  <div class="gochouqian">去测字</div>
                </div>
              </div>
              <div class="div_info mt25 ml15" @click="goitem('/name/info')">
                <div class="div_red">
                  <p style="font-size: 18px; color: #fff">姓名配对</p>
                  <p style="font-size: 14px">揭示姓名中缘分密码</p>
                  <div class="gochouqian">去配对</div>
                </div>
              </div>
               <div class="div_info mt25 ml15" @click="goitem('/namereport')">
                <div class="div_red">
                  <p style="font-size: 18px; color: #fff">姓名测算</p>
                  <p style="font-size: 14px">揭示姓名中命理要素</p>
                  <div class="gochouqian">去求测</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 大师申请认证 -->
      <div class="applyAnswer" @click="goAnswer()">
        <img src="../../../public/images/ApplyingImg.jpg" />
      </div>
      <!-- 知识内容 -->
      <div class="content2">
        <div class="content2_info">
          <div class="content2_top">
            <div class="content2_title">
              <div class="content2_radio"></div>
              <span class="ml13">八字知识</span>
            </div>
            <div class="content2_title ml40">
              <div class="content2_radio"></div>
              <span class="ml13">相术知识</span>
            </div>
            <div class="content2_title ml40">
              <div class="content2_radio"></div>
              <span class="ml13">周公解梦</span>
            </div>
            <div class="content2_title ml40">
              <div class="content2_radio"></div>
              <span class="ml13">起名知识</span>
            </div>
          </div>
          <div style="display: flex">
            <div class="content2_center">
              <template v-for="(item, index) in state.bazi" :key="index">
                <div class="content2_content mt9" @click="gozhishi(item)">
                  <span class="redRadios">●</span>&nbsp;<span style="margin-top: 1px">{{ item.name }}</span>
                </div>
              </template>
            </div>
            <div class="content2_center ml40">
              <template v-for="(item, index) in state.xiangshu" :key="index">
                <div class="content2_content mt9" @click="gozhishi(item)"><span class="redRadios">●</span>&nbsp;{{ item.name }}</div>
              </template>
            </div>
            <div class="content2_center ml40">
              <template v-for="(item, index) in state.fengshui" :key="index">
                <div class="content2_content mt9" @click="gozhishi(item)"><span class="redRadios">●</span>&nbsp;{{ item.name }}</div>
              </template>
            </div>
            <div class="content2_center ml40">
              <template v-for="(item, index) in state.qiming" :key="index">
                <div class="content2_content mt9" @click="gozhishi(item)"><span class="redRadios">●</span>&nbsp;{{ item.name }}</div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <my-footer></my-footer>
    <!-- <my-zixun></my-zixun> -->
    <my-modal></my-modal>
    <my-fantui></my-fantui>
    <div class="shangcheng" v-show="state.shangcheng">商城暂未开启，请 耐心等待</div>
    <div :style="state.style" class="show_H5">
      <!-- <div class="guangao">
				<template v-if="state.xmpd">
					<img src="../../../public/images/xmpd.png" alt="" class="image" style="cursor: pointer;"
						@click="goH5('https://www.ydxc.cc/ydxc-measurement/#/hunYin-report')">
					<img src="../../../public/images/guanbiguanggao.png" style="margin-top: 23px;cursor: pointer;" @click="gaunbi(0)">
				</template>
				<template v-else>
					<div class="image"></div>
					<div style="margin-top: 23px;height: 40px;"></div>
				</template>
			</div> -->
      <!-- <div class="guangao">
        <template v-if="state.zwds">
          <img src="../../../public/images/zwds.png" alt="" class="image" style="cursor: pointer" @click="goH5('https://www.ydxc.cc/ydxc-measurement/#/ziWei-report')" />
          <img src="../../../public/images/guanbiguanggao.png" style="margin-top: 23px; cursor: pointer" @click="gaunbi(1)" />
        </template>
      </div> -->
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import Header from "../../components/header.vue";
import Footer from "../../components/footer.vue";
import Model from "../../components/modal.vue";
import fantuimod from "../../components/fantuimod.vue";
import bazipaipan from "../../components/bazipaipan.vue";
import rightpanels from "../../components/right-panels.vue";
import { getZhishiList, getBookList, getBookmark, getUserInfo } from "../../api/newNetapi.js";
import { page } from "../../api/shipin.js";
import { getadList } from "../../api/ad.js";
import SessionStorage from "../../common/scripts/session-storage.js";
import bus from "../../common/scripts/bus";
export default defineComponent({
  components: {
    "my-header": Header,
    "my-footer": Footer,
    "my-modal": Model,
    "my-fantui": fantuimod,
    "my-bazi": bazipaipan,
    "my-rightpanels": rightpanels, //黄历
  },
  name: "my-home",
  setup() {
    const router = useRouter();

    const state = reactive({
      ad: [
        {
          id: 1,
          image: "../../../public/images/gouhuo-bg.jpg",
        },
        {
          id: 2,
          image: "../../../public/images/gouhuo-bg.jpg",
        },
      ],
      adList: [],
      //八字知识
      bazi: [],
      // 相术知识
      xiangshu: [],
      // 风水知识
      fengshui: [],
      // 起名知识
      qiming: [],
      code: "",
      bookList: [],
      bookId: "",
      juans: [],
      //卷章内容列表
      juansContent: [],
      //序章列表
      xuList: [],
      bookMarkId: "",
      videoList: [],
      userInfo: null,
      currentSlide: 0,
      shangcheng: false,
      style: {
        top: "528px",
        left: "0px",
      },
      xmpd: true,
      zwds: true,
      xinnianInfo: false,
    });

    const toSearch = function () {};

    const mouseover = (index) => {
      console.log(index);
      let mask = document.querySelectorAll(".Mask");
      mask.forEach((item) => {
        item.classList.add("none");
      });
      mask[index].classList.remove("none");
    };

    const blur = (index) => {
      let mask = document.querySelectorAll(".Mask");
      mask[index].classList.add("none");
    };

    //获取邀请人code
    const getCode = function () {
      let url = window.location.href;
      let code = url.split("=")[1];
      // console.log(code);
      if (code) {
        state.code = code;
        localStorage.setItem("invite-code", code);
      } else {
        return;
      }
    };
    const getuserInfo = () => {
      getUserInfo().then(getuserSuccess).catch();
    };

    const getuserSuccess = (result) => {
      if (result.data.status == 1) {
        state.userInfo = result.data.data;
      } else {
        state.userInfo = null;
      }
    };
    getuserInfo();

    const IsPC = function () {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    };

    const getAdSuccess = (res) => {
      if (res.data.status == 1) {
        const { data } = res.data;
        console.log("广告", data);
        state.adList = data;
        state.currentSlide = 0;
        if (data.length >= 1) {
          document.querySelector(".swiper").style.width = `${data.length * 100}vw`;
          //   setInterval(nextSlide, 6000);
        }
      }
    };

    const nextSlide = () => {
      let swiper = document.querySelector(".swiper");
      let img = document.querySelectorAll(".slide");
      let firstimg = img[0].cloneNode(true);
      if (state.currentSlide === 0) {
        state.currentSlide = 1;
      }
      if (state.currentSlide != img.length) {
        swiper.style.transform = `translate(-${state.currentSlide * 100}vw)`;
        swiper.style.transition = `transform 2s ease`;
        state.currentSlide++;
      } else {
        swiper.style.width = `${(img.length + 1) * 100}vw`;
        swiper.appendChild(firstimg);
        state.currentSlide = 0;
        setTimeout(() => {
          swiper.style.transform = `translate(-${img.length * 100}vw)`;
          swiper.style.transition = `transform 2s ease`;
        }, 50);
        setTimeout(() => {
          let img = document.querySelectorAll(".slide");
          swiper.removeChild(img[img.length - 1]);
          swiper.style.width = `${(img.length - 1) * 100}vw`;
          swiper.style.transform = `translate(0)`;
          swiper.style.transition = `null`;
        }, 3000);
      }
    };

    // const lunboH5 = () => {
    // 	setTimeout(() => {
    // 		let image = document.querySelectorAll('.image')
    // 		let lunbo = document.querySelector('.lunbo')
    // 		let a = image[0].cloneNode(true);
    // 		lunbo.appendChild(a)
    // 		console.log('111', image);
    // 		console.log('222', a);
    // 		console.log('333', lunbo);
    // 		lunbo.style.transform = `translate(0px)`
    // 		lunbo.style.transition = `transform 2s ease`;
    // 		setTimeout(() => {
    // 			lunbo.style.transform = `translate(-206px)`
    // 			lunbo.style.transition = `transform 2s ease`;
    // 		}, 2500)
    // 		setTimeout(() => {
    // 			lunbo.style.transform = `translate(-412px)`
    // 			lunbo.style.transition = `transform 2s ease`;
    // 		}, 5000)
    // 		setTimeout(() => {
    // 			lunbo.style.transform = `translate(0px)`
    // 			lunbo.style.transition = `transform ease`;
    // 		}, 50)
    // 	}, 1)
    // }

    // 倒计时小年更换图片
    const djs = () => {
      let a = +new Date("2024-2-25 00:00:00");
      console.log("目标时间", a);
      let b = +new Date();
      console.log("当前时间", b);
      let c = a - b;
      if (c > 0) {
        state.xinnianInfo = false;
        console.log("当前时间", new Date());
      } else {
        state.xinnianInfo = true;
        console.log("当前时间", new Date());
      }
    };

    const init = function () {
      // state.item1 = setInterval(lunboH5, 6050)
      getCode();
      djs();
      IsPC();
      SessionStorage.set("nie", null);
      getadList({
        type: 1,
      })
        .then(getAdSuccess)
        .catch();

      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        //alert(navigator.userAgent);
        if (state.code) {
          window.location.href = `https://www.ydxc.cc/ydxc-mobile/#/?code=${state.code}`;
        } else {
          window.location.href = "https://www.ydxc.cc/ydxc-mobile/#/";
        }
      } else if (/(Android)/i.test(navigator.userAgent)) {
        //alert(navigator.userAgent);
        // window.location.href = "Android.html";
        if (state.code) {
          window.location.href = `https://www.ydxc.cc/ydxc-mobile/#/?code=${state.code}`;
        } else {
          window.location.href = "https://www.ydxc.cc/ydxc-mobile/#/";
        }
      } else {
        // window.location.href = "https://www.ydxc.cc/ydxc-station/#/";
      }
      getZhishiList({
        type: 1,
      })
        .then((res) => {
          state.bazi = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
      getZhishiList({
        type: 2,
      })
        .then((res) => {
          state.xiangshu = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
      getZhishiList({
        type: 3,
      })
        .then((res) => {
          state.fengshui = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
      getZhishiList({
        type: 4,
      })
        .then((res) => {
          state.qiming = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
      getBookList()
        .then((res) => {
          if (res.data.data.length > 11) {
            for (let i = 0; i < 10; i++) {
              state.bookList.push(res.data.data[i]);
            }
          } else {
            state.bookList = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    init();
    //切换
    const Switchingtools = function (n) {
      // 按钮
      let bt = document.querySelectorAll("#ctbtn");
      //轮播
      let lunbo = document.querySelector(".content1_lunbo");
      //轮播内部div
      let div = document.querySelectorAll("#div");
      //轮播内部div的第一个
      let firstdiv = div[0].cloneNode(true);
      //轮播内部div的最后一个
      let lastdiv = div[div.length - 1].cloneNode(true);
      let currntIndex = null;
      bt.forEach((item, index) => {
        if (item.classList.contains("active")) {
          currntIndex = index;
        }
        item.classList.remove("active");
      });
      if (currntIndex == 0 && n == 2) {
        // 第一张跳到最后一张
        lunbo.style.width = `4800px`;
        lunbo.insertBefore(lastdiv, div[0]);
        lunbo.style.transform = `translate(-1200px)`;
        setTimeout(() => {
          lunbo.style.transform = `translate(0)`;
          lunbo.style.transition = `transform 0.5s ease`;
        }, 50);
        setTimeout(() => {
          let div = document.querySelectorAll("#div");
          lunbo.removeChild(div[0]);
          lunbo.style.transform = `translate(-2400px)`;
          lunbo.style.transition = `null`;
        }, 450);
      } else if (currntIndex == 2 && n == 0) {
        // 最后一张返回第一张
        lunbo.style.width = `4800px`;
        lunbo.appendChild(firstdiv);
        setTimeout(() => {
          lunbo.style.transform = `translate(-3600px)`;
          lunbo.style.transition = `transform 0.4s ease`;
        }, 50);
        setTimeout(() => {
          let div = document.querySelectorAll("#div");
          lunbo.removeChild(div[div.length - 1]);
          lunbo.style.transform = `translate(0)`;
          lunbo.style.transition = `null`;
        }, 450);
      } else {
        lunbo.style.transform = `translate(-${n * 1200}px)`;
        lunbo.style.transition = `transform 0.4s ease`;
      }
      bt[n].classList.add("active");
      // console.log(bt);
    };

    const getvideoList = () => {
      page({
        page: 1,
        pageSize: 4,
      })
        .then(getvideoSuccess)
        .catch();
    };
    const getvideoSuccess = (result) => {
      if (result.data.status == 1) {
        const { data } = result.data;
        console.log(data);
        state.videoList = data.records;
      }
    };
    getvideoList();

    const goHunagli = function () {
      router.push("/huangli/info");
    };

    const goitem = function (item) {
      router.push(item);
    };

    const goBook = function (item) {
      state.bookId = item.id;
      console.log(item);
      getBookmark({
        bookId: state.bookId,
      })
        .then((res) => {
          //书签信息
          state.bookMarkInfo = res.data.data;
          let bookMarkInfo = res.data.data;
          if (JSON.stringify(bookMarkInfo) === "{}") {
            //没有书签
            router.push({
              path: "/guji/info",
              query: {
                bookId: item.id,
              },
            });
          } else {
            router.push({
              path: "/guji/info",
              query: {
                zhangId: res.data.data.id,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    //去知识页
    const gozhishi = function (item) {
      router.push({
        path: "/zhishi/info",
        query: {
          id: item.id,
        },
      });
    };
    const qu = (val) => {
      let url = router.resolve({
        path: val,
      });
      window.open(url.href);
    };

    //去视频详情页
    const goVideo = (id) => {
      let url = router.resolve({
        path: "/video/info",
        query: {
          videoId: id,
        },
      });
      window.open(url.href);
    };

    //去视频列表页
    const goVideoList = () => {
      router.push("/shipin/list");
    };

    //判断是否是48h内
    const determineTime = (time) => {
      var now = +new Date();
      var Time = +new Date(time);
      //相差时间戳
      var phaseDifference = now - Time;
      //48小时时间戳
      var timeDifference = 2 * 24 * 60 * 60 * 1000;
      if (timeDifference > phaseDifference) {
        return false;
      } else {
        return true;
      }
    };
    const shangcheng = () => {
      state.shangcheng = true;
      setTimeout(() => {
        state.shangcheng = false;
      }, 3000);
    };

    const goAnswer = () => {
      if (state.userInfo) {
        router.push("/answerInfo");
      } else {
        bus.emit("denglu-show", {});
      }
    };
    const gaunbi = (index) => {
      // let a = document.querySelectorAll('.guangao')
      // a[index].style.opacity = '0'
      if (index == 0) {
        state.xmpd = false;
      } else if (index == 1) {
        state.zwds = false;
      }
    };
    const goH5 = (val) => {
      window.location.href = val;
    };
    return {
      state: state,
      toSearch: toSearch,
      Switchingtools: Switchingtools,
      goHunagli: goHunagli,
      goitem: goitem,
      goBook: goBook,
      gozhishi: gozhishi,
      qu: qu,
      goVideo,
      goVideoList,
      determineTime,
      mouseover,
      blur,
      shangcheng,
      goAnswer,
      gaunbi: gaunbi,
      goH5: goH5,
      router,
    };
  },
  mounted() {
    let app = document.getElementById("app");
    let rect = app.getBoundingClientRect();
    let left = (rect.width - 1100) / 2;
    this.state.style.left = left - 300 + 14 + "px";
  },
});
</script>

<style src="./css/home.css"></style>
