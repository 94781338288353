<template>
  <div v-show="state.isShow" class="my-denglu">
    <div class="neirong">
      <img src="../../public/images/guanbi.png" style="position: absolute; right: 21px; top: 22px; cursor: pointer; z-index: 2" @click="guanbi" />
      <template v-if="state.wx">
        <div class="wx">
          <div style="line-height: 24px; font-size: 24px">微信扫码登录</div>
          <div style="line-height: 14px; font-size: 14px; color: #999; margin-top: 19px; font-weight: 200">未注册的微信号将自动创建篝火账号</div>
          <div class="ewm">
            <div class="erweima"></div>
            <div style="margin-top: 11px"><img src="../../public/images/wxsaoma.png" /></div>
          </div>
          <div class="sjhdl" @click="dlqiehuan(0)"><img src="../../public/images/sjhzhuce.png" alt="" /><span style="margin-left: 6px; font-weight: 200; font-size: 15px; margin-top: 1px">手机登录/注册</span></div>
        </div>
      </template>
      <template v-else>
        <div class="sjh">
          <div style="line-height: 24px; font-size: 24px">手机验证码登录</div>
          <div style="line-height: 14px; font-size: 14px; color: #999; margin-top: 19px; font-weight: 200">未注册的手机号将自动创建账号</div>
          <template v-if="state.yzm">
            <div class="sjhdl">
              <img src="../../public/images/sjhdl.png" alt="" />
              <input type="text" class="sjhsr" v-model="state.sjhValue" placeholder="请输入常用手机号码" @keyup="sjh" @click="add" @blur="remove" />
            </div>
            <template v-if="state.sjhpd">
              <div class="qingqiu">下一步</div>
            </template>
            <template v-else>
              <div class="add" @click="getyzm">下一步</div>
              <div v-show="state.tishi" class="tishi">{{ state.text }}</div>
            </template>
            <div class="wxdl" @click="dlqiehuan(1)"><img src="../../public/images/wxdl.png" alt="" /><span style="margin-left: 6px; font-weight: 200; font-size: 15px; margin-top: 1px">微信登录/注册</span></div>
          </template>
          <template v-else>
            <img src="../../public/images/fanhuishangji.png" style="position: absolute; top: 21px; left: 20px; z-index: 2; cursor: pointer" @click="fanhui" />
            <div style="margin: auto; margin-top: 47px; font-weight: 200; line-height: 16px">
              请输入发送至 <span style="font-weight: 800">{{ state.sjhValue[0] }}{{ state.sjhValue[1] }}{{ state.sjhValue[2] }}******{{ state.sjhValue[9] }}{{ state.sjhValue[10] }}</span> 的 6 位验证码
            </div>
            <div class="yzm">
              <input type="text" @keyup="yzm1" v-model="state.yzm1" class="yzm1 add1" autofocus maxlength="1" />
              <input type="text" @keyup="yzm2" v-model="state.yzm2" class="yzm2" maxlength="1" />
              <input type="text" @keyup="yzm3" v-model="state.yzm3" class="yzm3" maxlength="1" />
              <div style="width: 10px; height: 1px; background-color: #cdcdcd"></div>
              <input type="text" @keyup="yzm4" v-model="state.yzm4" class="yzm4" maxlength="1" />
              <input type="text" @keyup="yzm5" v-model="state.yzm5" class="yzm5" maxlength="1" />
              <input type="text" @keyup="yzm6" v-model="state.yzm6" class="yzm6" maxlength="1" />
            </div>
            <template v-if="state.djs">
              <div style="margin-top: 16px; font-size: 14px; color: #999; line-height: 14px; font-weight: 200">{{ state.s }}秒后重新获取验证码</div>
            </template>
            <template v-else>
              <div style="width: 98px; margin: auto; margin-top: 16px; font-size: 14px; color: #e64b39; line-height: 14px; font-weight: 200; border-bottom: 1px solid #e64b39; cursor: pointer" @click="getyzm">重新获取验证码</div>
            </template>
            <template v-if="state.dl">
              <div class="dl">登录</div>
            </template>
            <template v-else>
              <div class="dl1" @click="dl">登录</div>
            </template>
            <!-- <div class="wxdl1" @click="dlqiehuan(0)">
							<img src="../../public/images/wxdl.png" alt=""><span
								style="margin-left: 6px;font-weight: 200;font-size: 15px;margin-top: 1px;">微信登录/注册</span>
						</div> -->
          </template>
        </div>
      </template>
      <div class="xieyi">注册登录即代表同意<span style="cursor: pointer" @click="gotu('/yinghuxieyi')">《用户协议》</span><span style="cursor: pointer" @click="gotu('/yinsixieyi')">《隐私政策》</span></div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import QRCode from "qrcodejs2";
import bus from "../common/scripts/bus.js";
import SessionStorage from "../common/scripts/session-storage.js";
import {
  loginAndReg,
  getphoneNumber,
  gzhLoginRes,
  loginOpenId,
  // getUserInfo,
} from "../api/newNetapi.js";
import { useRouter } from "vue-router";
export default defineComponent({
  components: {},
  name: "my-bazi",
  setup() {
    const router = useRouter();
    // let {
    // 	push
    // } = useRouter()
    const state = reactive({
      wx: true,
      isShow: false,
      sjh: true,
      sjhpd: true,
      sjhValue: "",
      tsxt: "您输入的电话号码有误",
      tishi: true,
      yzm: true,
      yzm1: "",
      yzm2: "",
      yzm3: "",
      yzm4: "",
      yzm5: "",
      yzm6: "",
      s: 60,
      item: "",
      djs: true,
      yzmshu: "",
      dl: true,
      item1: null,
      result: "",
    });
    //初始化数据
    const init = function () {
      bus.on("denglu-show", dengluShow);
      document.body.scrollTop = 0;
    };
    const dengluShow = () => {
      state.isShow = true;
      getrandomStr();
      state.item1 = setInterval(lunxun, 1000);
    };
    const guanbi = () => {
      state.isShow = false;
      clearTimeout(state.item1);
    };
    // 微信登录js
    const getrandomStr = function () {
      let result = "";
      let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let charactersLength = characters.length;
      for (let i = 0; i < 5; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      erweima(result);
      state.result = result;
    };
    // 二维码
    const erweima = (val) => {
      console.log(val);
      let a = document.querySelector(".erweima");
      let uri = "https%3A%2F%2Fwww.ydxc.cc%2Fydxc%2Fopen%2Fpath%2FgzhLogin";
      a.innerHTML = "";
      new QRCode(a, {
        text: `https://www.ydxc.cc/ydxc/open/v2/gzhLogin?scope=snsapi_userinfo&state=${val}&redirectUri=${uri}`, // 需要转换为二维码的内容
        width: 184,
        height: 184,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    };
    // 扫码登录
    const lunxun = () => {
      console.log();
      let obj = {
        state: state.result,
      };
      gzhLoginRes(obj)
        .then((res) => {
          if (res.status == 200 && res.data.status == 1) {
            if (res.data.data.openId != "") {
              console.log(111);
              // clearInterval(state.tiem1)
              let obj1 = {
                openId: res.data.data.openId,
              };
              loginOpenId(obj1)
                .then((res) => {
                  if (res.status == 200 && res.data.status == 1) {
                    clearInterval(state.item1);
                    state.item1 = null;
                    location.reload();
                    state.isShow = false;
                    SessionStorage.set("user-info", true);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              console.log("没获取到捏");
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    // 切换登录方式
    const dlqiehuan = (val) => {
      if (val == 0) {
        clearInterval(state.item1);
        state.item1 = null;
        console.log(state.item1);
        state.wx = false;
        state.yzm = true;
      } else if (val == 1) {
        state.wx = true;
        state.item1 = setInterval(lunxun, 1000);
      }
    };

    // 手机号登录js
    //正则验证电话号码格式
    const validatePhoneNumber = function (phoneNumber) {
      var pattern = /^1[3456789]\d{9}$/;
      return pattern.test(phoneNumber);
    };
    // 获取验证码
    const getyzm = () => {
      if (state.sjhValue != "") {
        if (validatePhoneNumber(state.sjhValue)) {
          getphoneNumber(state.sjhValue)
            .then((res) => {
              if (res.status == 200 && res.data.status == 1) {
                state.yzm = false;
                state.djs = true;
                state.s = 60;
                state.tiem = setInterval(djs, 1000);
              } else {
                state.sjhpd = false;
                state.text = res.data.msg;
                let a = document.querySelector(".tishi");
                a.style.left = "117px";
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          state.text = "您输入的电话号码有误";
        }
      } else {
        state.text = "您输入的电话号码有误";
      }
    };
    // 检测手机号位数
    const sjh = () => {
      if (state.sjhValue.length >= 11) {
        state.sjhpd = false;
      } else {
        state.sjhpd = true;
      }
    };
    // 获取6个input框
    const yzm1 = () => {
      state.showKeyboard = true;
      let yzm2 = document.querySelector(".yzm2");
      state.yzm1 = Number1(state.yzm1);
      if (state.yzm1.length == 1) {
        yzm2.classList.add("add1");
        yzm2.focus();
        state.yzm2a = false;
        yanzheng();
      } else {
        yanzheng();
      }
    };
    const yzm2 = () => {
      let yzm1 = document.querySelector(".yzm1");
      let yzm2 = document.querySelector(".yzm2");
      let yzm3 = document.querySelector(".yzm3");
      state.yzm2 = Number1(state.yzm2);
      if (state.yzm2.length == 0) {
        yanzheng();
        if (!state.yzm2a) {
          state.yzm2a = true;
        } else {
          yzm1.focus();
          yzm2.classList.remove("add1");
        }
      } else {
        yanzheng();
        yzm3.classList.add("add1");
        state.yzm3a = false;
        yzm3.focus();
      }
    };
    const yzm3 = () => {
      let yzm2 = document.querySelector(".yzm2");
      let yzm3 = document.querySelector(".yzm3");
      let yzm4 = document.querySelector(".yzm4");
      state.yzm3 = Number1(state.yzm3);
      if (state.yzm3.length == 0) {
        yanzheng();
        if (!state.yzm3a) {
          state.yzm3a = true;
        } else {
          yzm2.focus();
          yzm3.classList.remove("add1");
          state.yzm2a = false;
        }
      } else {
        yanzheng();
        yzm4.classList.add("add1");
        yzm4.focus();
        state.yzm4a = false;
      }
    };
    const yzm4 = () => {
      let yzm3 = document.querySelector(".yzm3");
      let yzm4 = document.querySelector(".yzm4");
      let yzm5 = document.querySelector(".yzm5");
      state.yzm4 = Number1(state.yzm4);
      if (state.yzm4.length == 0) {
        yanzheng();
        if (!state.yzm4a) {
          state.yzm4a = true;
        } else {
          yzm3.focus();
          yzm4.classList.remove("add1");
          state.yzm3a = false;
        }
      } else {
        yanzheng();
        yzm5.classList.add("add1");
        yzm5.focus();
        state.yzm5a = false;
      }
    };
    const yzm5 = () => {
      let yzm4 = document.querySelector(".yzm4");
      let yzm5 = document.querySelector(".yzm5");
      let yzm6 = document.querySelector(".yzm6");
      state.yzm5 = Number1(state.yzm5);
      if (state.yzm5.length == 0) {
        yanzheng();
        if (!state.yzm5a) {
          state.yzm5a = true;
        } else {
          yzm4.focus();
          yzm5.classList.remove("add1");
          state.yzm4a = false;
        }
      } else {
        yanzheng();
        yzm6.classList.add("add1");
        yzm6.focus();
        state.yzm6a = false;
      }
    };
    const yzm6 = () => {
      let yzm5 = document.querySelector(".yzm5");
      let yzm6 = document.querySelector(".yzm6");
      state.yzm6 = Number1(state.yzm6);
      if (state.yzm6.length == 0) {
        yanzheng();
        state.dl = true;
        if (!state.yzm6a) {
          state.yzm6a = true;
        } else {
          yzm5.focus();
          yzm6.classList.remove("add1");
          console.log("yzm", state.yzmshu);
          state.yzm5a = false;
        }
      } else {
        yanzheng();
        if (state.yzmshu.length == 6 && state.yzmshu[5] != undefined) {
          state.dl = false;
        }
      }
    };
    // 拼接验证码
    const yanzheng = () => {
      state.yzmshu = state.yzm1 + state.yzm2 + state.yzm3 + state.yzm4 + state.yzm5 + state.yzm6;
      console.log(state.yzmshu);
    };
    // 判断是否数字
    const Number1 = (val) => {
      if (val == "1" || val == "2" || val == "3" || val == "4" || val == "5" || val == "6" || val == "7" || val == "8" || val == "9" || val == "0") {
        return val;
      } else {
        return "";
      }
    };
    // 倒计时
    const djs = () => {
      if (state.s > 0) {
        state.s = state.s - 1;
      } else {
        state.djs = false;
        clearTimeout(state.tiem);
      }
    };
    // 登录
    const dl = () => {
      let obj = {
        loginNo: state.sjhValue,
        code: state.yzmshu,
      };
      loginAndReg(obj)
        .then((res) => {
          if (res.status == 200 && res.data.status == 1) {
            state.isShow = false;
            SessionStorage.set("user-info", true);
            clearTimeout(state.tiem1);
            location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const fanhui = () => {
      state.yzm = true;
      state.sjhValue = "";
    };
    const gotu = (val) => {
      let url = router.resolve({
        path: val,
      });
      window.open(url.href);
    };
    // 点击外边框
    const add = () => {
      let a = document.querySelector(".sjhdl");
      a.classList.add("se");
    };
    // 取消外边框
    const remove = () => {
      let a = document.querySelector(".sjhdl");
      a.classList.remove("se");
    };
    init();
    return {
      state: state,
      guanbi: guanbi,
      erweima: erweima,
      dlqiehuan: dlqiehuan,
      getyzm: getyzm,
      sjh: sjh,
      yzm1: yzm1,
      yzm2: yzm2,
      yzm3: yzm3,
      yzm4: yzm4,
      yzm5: yzm5,
      yzm6: yzm6,
      dl: dl,
      fanhui: fanhui,
      gotu: gotu,
      add: add,
      remove: remove,
    };
  },
});
</script>

<style>
.my-denglu {
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 9999;
  position: fixed;
  color: #252525;
  top: 0px;
  left: 0px;
  bottom: 0;
  right: 0;
}

.my-denglu .neirong {
  width: 480px;
  height: 548px;
  background-color: #fff;
  border: 1px solid #eaeaea;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -274px;
  margin-left: -240px;
  z-index: 3;
}

.my-denglu .neirong .wx {
  height: 490px;
  position: relative;
  padding-top: 50px;
}

.my-denglu .neirong .wx .ewm {
  width: 210px;
  height: 248px;
  margin: auto;
  margin-top: 23px;
  box-shadow: 0px 0px 10px 1px #eaeaea;
  padding-top: 13px;
  padding-left: 13px;
  padding-right: 13px;
}

.my-denglu .neirong .wx .ewm .erweima {
  width: 184px;
  height: 184px;
}

.my-denglu .neirong .wx .sjhdl {
  margin: auto;
  margin-top: 38px;
  width: 155px;
  height: 42px;
  border: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.my-denglu .neirong .xieyi {
  height: 58px;
  background-color: #fafafa;
  color: #999;
  font-weight: 200;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-denglu .neirong .sjh {
  height: 490px;
  position: relative;
  padding-top: 50px;
}

.my-denglu .neirong .sjh .sjhdl {
  width: 380px;
  margin: auto;
  margin-top: 47px;
  height: 42px;
  border: 1px solid #eaeaea;
  padding-left: 15px;
  text-align: left;
  display: flex;
  align-items: center;
}

.my-denglu .neirong .sjh .sjhdl .sjhsr {
  width: 330px;
  height: 40px;
  border: none;
  margin-left: 12px;
  font-weight: 200;
}

.my-denglu input::-webkit-input-placeholder {
  color: #999 !important;
}

.my-denglu input:-moz-placeholder {
  color: #999 !important;
}

.my-denglu input::-moz-placeholder {
  color: #999 !important;
}

.my-denglu input:-ms-input-placeholder {
  color: #999 !important;
}

.my-denglu .neirong .sjh .qingqiu {
  margin: auto;
  margin-top: 47px;
  width: 161px;
  height: 42px;
  background-color: #cdcdcd;
  color: #fff;
  line-height: 42px;
}

.my-denglu .neirong .sjh .add {
  margin: auto;
  margin-top: 47px;
  width: 161px;
  height: 42px;
  background-color: #e64b39;
  color: #fff;
  line-height: 42px;
}

.my-denglu .neirong .sjh .add:active {
  background-color: #c53423 !important;
}

.my-denglu .neirong .sjh .dl,
.my-denglu .neirong .sjh .dl1 {
  width: 161px;
  height: 42px;
  background-color: #cdcdcd;
  margin: auto;
  margin-top: 32px;
  line-height: 42px;
  color: #fff;
}

.my-denglu .neirong .sjh .dl1 {
  background-color: #e64b39;
}

.my-denglu .neirong .sjh .dl1:active {
  background-color: #c53423 !important;
}

.my-denglu .neirong .sjh .wxdl,
.my-denglu .neirong .sjh .wxdl1 {
  margin: auto;
  margin-top: 131px;
  width: 155px;
  height: 42px;
  border: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.my-denglu .neirong .sjh .wxdl1 {
  margin-top: 81px;
}

.my-denglu .neirong .sjh .tishi {
  margin-top: 20px;
  position: absolute;
  font-size: 14px;
  line-height: 14px;
  font-weight: 200;
  color: #e64b39;
  left: 170px;
}

.my-denglu .neirong .sjh .yzm {
  margin: auto;
  margin-top: 20px;
  width: 340px;
  height: 42px;
  text-align: left;
  display: flex;
  align-items: center;
}

.my-denglu .neirong .sjh .yzm .add1 {
  border: 1px solid #e64b39 !important;
}

.my-denglu .neirong .sjh .yzm .yzm1,
.my-denglu .neirong .sjh .yzm .yzm2,
.my-denglu .neirong .sjh .yzm .yzm3,
.my-denglu .neirong .sjh .yzm .yzm4,
.my-denglu .neirong .sjh .yzm .yzm5,
.my-denglu .neirong .sjh .yzm .yzm6 {
  width: 40px;
  height: 42px;
  border: 1px solid #eaeaea;
  padding-left: 15px;
  font-size: 18px;
}

.my-denglu .neirong .sjh .yzm .yzm1 {
  margin-right: 8px;
}

.my-denglu .neirong .sjh .yzm .yzm2,
.my-denglu .neirong .sjh .yzm .yzm5 {
  margin-right: 8px;
  margin-left: 8px;
}

.my-denglu .neirong .sjh .yzm .yzm3 {
  margin-left: 8px;
  margin-right: 13px;
}

.my-denglu .neirong .sjh .yzm .yzm4 {
  margin-left: 13px;
  margin-right: 8px;
}

.my-denglu .neirong .sjh .yzm .yzm6 {
  margin-left: 8px;
}

.my-denglu .se {
  border: 1px solid #999 !important;
  /* color: #e64b39; */
}
</style>
