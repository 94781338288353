<template>
  <keep-alive>
    <router-view />
  </keep-alive>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  setup() {
    return {};
  },
});
</script>

<style>
@import url("./common/style/media.css");

html,
body {
  /* overflow-y: auto; */
  min-height: 100vh;
  font-family: 微软雅黑;
  height: 100%;
  overflow-x: hidden;
}

.messageCard {
  overflow-y: auto;
}
input:focus-visible {
  border: 1px solid #999;
}
.messageCard::-webkit-scrollbar {
  width: 4px;
}

.messageCard::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

.messageCard::-webkit-scrollbar-track {
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

body::-webkit-scrollbar-track {
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

#app {
  font-family: 微软雅黑;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #252525;
  height: 100vh;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
video {
  width: 100%;
  height: 100%;
  /* object-fit: fill !important; */
  display: block ;
  outline: none !important;
  mix-blend-mode: screen;
}
video:focus {
  outline: none;
}
</style>
