let arr = [];
for (let i = 1200; i < 2101; i++) {
  arr.push(i);
}
export const years = arr;

export const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const dates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
export const date1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
export const date2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29];
export const date3 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];

export const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

export const minutes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];

export const ygzArr = [
  "年柱",
  "甲子",
  "甲寅",
  "甲辰",
  "甲午",
  "甲申",
  "甲戌",

  "乙丑",
  "乙卯",
  "乙巳",
  "乙未",
  "乙酉",
  "乙亥",

  "丙子",
  "丙寅",
  "丙辰",
  "丙午",
  "丙申",
  "丙戌",

  "丁丑",
  "丁卯",
  "丁巳",
  "丁未",
  "丁酉",
  "丁亥",

  "戊子",
  "戊寅",
  "戊辰",
  "戊午",
  "戊申",
  "戊戌",

  "己丑",
  "己卯",
  "己巳",
  "己未",
  "己酉",
  "己亥",

  "庚子",
  "庚寅",
  "庚辰",
  "庚午",
  "庚申",
  "庚戌",

  "辛丑",
  "辛卯",
  "辛巳",
  "辛未",
  "辛酉",
  "辛亥",

  "壬子",
  "壬寅",
  "壬辰",
  "壬午",
  "壬申",
  "壬戌",

  "癸丑",
  "癸卯",
  "癸巳",
  "癸未",
  "癸酉",
  "癸亥",
];

export const mgzArr = ["月柱", "寅月", "卯月", "辰月", "已月", "午月", "未月", "中月", "西月", "成月", "亥月", "子月", "丑月"];

export const dgzArr = [
  "日柱",
  "甲子",
  "甲寅",
  "甲辰",
  "甲午",
  "甲申",
  "甲戌",

  "乙丑",
  "乙卯",
  "乙巳",
  "乙未",
  "乙酉",
  "乙亥",

  "丙子",
  "丙寅",
  "丙辰",
  "丙午",
  "丙申",
  "丙戌",

  "丁丑",
  "丁卯",
  "丁巳",
  "丁未",
  "丁酉",
  "丁亥",

  "戊子",
  "戊寅",
  "戊辰",
  "戊午",
  "戊申",
  "戊戌",

  "己丑",
  "己卯",
  "己巳",
  "己未",
  "己酉",
  "己亥",

  "庚子",
  "庚寅",
  "庚辰",
  "庚午",
  "庚申",
  "庚戌",

  "辛丑",
  "辛卯",
  "辛巳",
  "辛未",
  "辛酉",
  "辛亥",

  "壬子",
  "壬寅",
  "壬辰",
  "壬午",
  "壬申",
  "壬戌",

  "癸丑",
  "癸卯",
  "癸巳",
  "癸未",
  "癸酉",
  "癸亥",
];

export const hgzArr = ["时柱", "子时", "丑时", "寅时", "卯时", "辰时", "已时", "午时", "未时", "申时", "西时", "成时", "亥时"];

export const lunarMonthes = [
  {
    code: 1,
    name: "正",
  },
  {
    code: 2,
    name: "二",
  },
  {
    code: 3,
    name: "三",
  },
  {
    code: 4,
    name: "四",
  },
  {
    code: 5,
    name: "五",
  },
  {
    code: 6,
    name: "六",
  },
  {
    code: 7,
    name: "七",
  },
  {
    code: 8,
    name: "八",
  },
  {
    code: 9,
    name: "九",
  },
  {
    code: 10,
    name: "十",
  },
  {
    code: 11,
    name: "冬",
  },
  {
    code: 12,
    name: "腊",
  },
];

export const lunarDates = [
  {
    code: 1,
    name: "初一",
  },
  {
    code: 2,
    name: "初二",
  },
  {
    code: 3,
    name: "初三",
  },
  {
    code: 4,
    name: "初四",
  },
  {
    code: 5,
    name: "初五",
  },
  {
    code: 6,
    name: "初六",
  },
  {
    code: 7,
    name: "初七",
  },
  {
    code: 8,
    name: "初八",
  },
  {
    code: 9,
    name: "初九",
  },
  {
    code: 10,
    name: "初十",
  },
  {
    code: 11,
    name: "十一",
  },
  {
    code: 12,
    name: "十二",
  },
  {
    code: 13,
    name: "十三",
  },
  {
    code: 14,
    name: "十四",
  },
  {
    code: 15,
    name: "十五",
  },
  {
    code: 16,
    name: "十六",
  },
  {
    code: 17,
    name: "十七",
  },
  {
    code: 18,
    name: "十八",
  },
  {
    code: 19,
    name: "十九",
  },
  {
    code: 20,
    name: "二十",
  },
  {
    code: 21,
    name: "廿一",
  },
  {
    code: 22,
    name: "廿二",
  },
  {
    code: 23,
    name: "廿三",
  },
  {
    code: 24,
    name: "廿四",
  },
  {
    code: 25,
    name: "廿五",
  },
  {
    code: 26,
    name: "廿六",
  },
  {
    code: 27,
    name: "廿七",
  },
  {
    code: 28,
    name: "廿八",
  },
  {
    code: 29,
    name: "廿九",
  },
  {
    code: 30,
    name: "三十",
  },
];

export const lunarHours = [
  {
    code: 0,
    name: "子时(0时)",
  },
  {
    code: 1,
    name: "丑时(1时)",
  },
  {
    code: 2,
    name: "丑时(2时)",
  },
  {
    code: 3,
    name: "寅时(3时)",
  },
  {
    code: 4,
    name: "寅时(4时)",
  },
  {
    code: 5,
    name: "卯时(5时)",
  },
  {
    code: 6,
    name: "卯时(6时)",
  },
  {
    code: 7,
    name: "辰时(7时)",
  },
  {
    code: 8,
    name: "辰时(8时)",
  },
  {
    code: 9,
    name: "巳时(9时)",
  },
  {
    code: 10,
    name: "巳时(10时)",
  },
  {
    code: 11,
    name: "午时(11时)",
  },
  {
    code: 12,
    name: "午时(12时)",
  },
  {
    code: 13,
    name: "未时(13时)",
  },
  {
    code: 14,
    name: "未时(14时)",
  },
  {
    code: 15,
    name: "申时(15时)",
  },
  {
    code: 16,
    name: "申时(16时)",
  },
  {
    code: 17,
    name: "酉时(17时)",
  },
  {
    code: 18,
    name: "酉时(18时)",
  },
  {
    code: 19,
    name: "戌时(19时)",
  },
  {
    code: 20,
    name: "戌时(20时)",
  },
  {
    code: 21,
    name: "亥时(21时)",
  },
  {
    code: 22,
    name: "亥时(22时)",
  },
  {
    code: 23,
    name: "子时(23时)",
  },
];

export const titles = [
  {
    id: 1,
    name: "被邀请人",
    key: "invite",
    align: "center",
    type: "text",
  },
  {
    id: 2,
    name: "下单时间",
    key: "orderTime",
    align: "center",
    type: "text",
  },
  {
    id: 3,
    name: "支付信息",
    key: "payment",
    align: "center",
    type: "text",
  },
  {
    id: 4,
    name: "返利金额",
    key: "amount",
    align: "center",
    type: "text",
  },
  {
    id: 5,
    name: "返利状态",
    key: "status",
    align: "center",
    type: "text",
  },
];

export const datas = [
  {
    id: 1,
    invite: "1212",
    orderTime: "1212",
    payment: "1212",
    amount: 75,
    status: 1,
  },
  {
    id: 2,
    invite: "1212",
    orderTime: "1212",
    payment: "1212",
    amount: 75,
    status: 1,
  },
  {
    id: 3,
    invite: "1212",
    orderTime: "1212",
    payment: "1212",
    amount: 75,
    status: 1,
  },
  {
    id: 4,
    invite: "1212",
    orderTime: "1212",
    payment: "1212",
    amount: 75,
    status: 1,
  },
];

export const zhanShi = ["预测比赛", "预测天气", "预测财运", "预测合作", "预测出行", "预测行人", "预测失物", "预测学业", "预测事业", "预测婚姻"];
export const province = [
  { id: 1, name: "北京" },
  { id: 2, name: "上海" },
  { id: 3, name: "天津" },
  { id: 4, name: "重庆" },
  { id: 5, name: "河北" },
  { id: 6, name: "山西" },
  { id: 7, name: "河南" },
  { id: 8, name: "辽宁" },
  { id: 9, name: "吉林" },
  { id: 10, name: "黑龙江" },
  { id: 11, name: "内蒙古" },
  { id: 12, name: "江苏" },
  { id: 13, name: "山东" },
  { id: 14, name: "安徽" },
  { id: 15, name: "浙江" },
  { id: 16, name: "福建" },
  { id: 17, name: "湖北" },
  { id: 18, name: "湖南" },
  { id: 19, name: "广东" },
  { id: 20, name: "广西" },
  { id: 21, name: "江西" },
  { id: 22, name: "四川" },
  { id: 23, name: "海南" },
  { id: 24, name: "贵州" },
  { id: 25, name: "云南" },
  { id: 26, name: "西藏" },
  { id: 27, name: "陕西" },
  { id: 28, name: "甘肃" },
  { id: 29, name: "青海" },
  { id: 30, name: "宁夏" },
  { id: 31, name: "新疆" },
  { id: 52993, name: "港澳" },
  { id: 32, name: "台湾" },
];
