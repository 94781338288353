import { service } from "./axios.js";

//登录注册接口
export const loginAndReg = function (params) {
  return service.post("/open/login/phone/loginAndReg", params);
};
//手机号登录
export const getphoneNumber = function (params) {
  return service.get(`/open/message/code?phoneNumber=${params}`);
};
//手机号注册
export const toregister = function (params) {
  return service.post("/open/login/phone/register", params);
};
//账号登陆
export const account = function (params) {
  return service.post("/open/login/login", params);
};
//退出登录
export const logout = function () {
  return service.get(`/login/logout`);
};
//修改密码
export const updPwd = function (params) {
  return service.post("/login/updPwd", params);
};
//注册验证
export const yanzheng = function (params) {
  return service.get(`open/message/code/check?phoneNumber=${params.loginNo}&code=${params.code}`);
};
//忘记密码
export const forget = function (params) {
  return service.post("/open/login/forget", params);
};
//推广
export const promotion = function (params) {
  return service.post("/open/promotion/apply", params);
};
// 轮训登录状态
export const gzhLoginRes = function (params) {
  return service.post("/open/gzhLogin/res", params);
};
// 微信登录
export const loginOpenId = function (params) {
  return service.post("/open/login/openId", params);
};
// 获取个人信息
export const getUserInfo = function (params) {
  return service.post("/buserinfo/getUserInfo", params);
};
// 更新个人信息
export const edit = function (params) {
  return service.post("/buserinfo/edit", params);
};
// 上传图片
export const uploadImg = function (params) {
  return service.post("/upload/uploadImg", params);
};
// 获取微信信息
export const wxInfo = function (params) {
  return service.get(`/open/wx/info?openid=${params}`);
};
// 获取微信绑定
export const wxBind = function (params) {
  return service.post("/buserinfo/wx/bind", params);
};

//大师认证
export const authentication = function (params) {
  return service.post("/buserinfo/v2/authentication", params);
};

// 网站接口2.0

export const getZhishiList = function (params) {
  return service.post(`/open/knowledge/list`, params);
};
//根据id获取知识
export const getZhishiById = function (params) {
  return service.get(`/open/knowledge/getById?id=${params}`);
};

//获取上架图书列表 /open/book/list
export const getBookList = function (params) {
  return service.get(`/open/book/list`, params);
};
//根据id获取图书
export const getBookById = function (params) {
  return service.get(`/open/book/getById?id=${params}`);
};
//根据章节id获取章节内容
export const getContentById = function (params) {
  return service.post(`/open/book/content/getById`, params);
};
//根据书籍id和卷id查询全部章节
export const getContentByBookId = function (params) {
  return service.post(`/open/book/content/list`, params);
};

//保存书签
export const saveBookmark = function (params) {
  return service.post(`/open/bookmark/set`, params);
};

//获取书签
export const getBookmark = function (params) {
  return service.post(`/open/bookmark/get`, params);
};

//获取卷宗
export const getJuans = function (params) {
  return service.get(`/open/book/juan/list?bookId=${params}`);
};

//根据章节Id获取卷id
export const getJuansbyContentId = function (params) {
  return service.get(`/open/book/juan/byContentId?contentId=${params}`);
};

//获取下一章  /open/book/content/next
export const getNextpage = function (params) {
  return service.post(`/open/book/content/next`, params);
};

export const getPrepage = function (params) {
  return service.post(`/open/book/content/pre`, params);
};

//获取随机知识 /open/knowledge/random?type=1&num=2
export const getrandomZhishi = function (params) {
  return service.get(`/open/knowledge/random?type=${params}&num=5`);
};

//获取随机知识
export const openBookList = function (params) {
  return service.get("/open/book/list", params);
};

//发题
export const distribution = function (params) {
  return service.post("/open/question/distribution", params);
};

// 答题活动提交
export const check = function (params) {
  return service.post("/comp/check", params);
};

//我的成绩查询
export const compMine = function (params) {
  return service.post("/open/comp/mine", params);
};

//排名
export const compRanking = function (params) {
  return service.post("/open/comp/ranking", params);
};
