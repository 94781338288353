<template>
	<footer>
		<div class="navigation">
			<div class="content">
				<div class="left">
					<div class="logo">
					</div>
				</div>
				<div style="width: 1px; height: 183px; background-color: #414141; transform:scaleX(1);margin-top: 20px;">
				</div>
				<div class="middle">
					<div class="middle-content">
						<div class="item" >
							<div>
								<img src="../../public/images/zixun.png" alt="" style="width: 112px;height: 112px;">
								<div style="margin-top: 12px; text-align: center;font-size: 14px;">
									咨询
								</div>
							</div>
						</div>
						<div class="item">
							<div>
								<img src="../../public/images/hezuo.png" alt="" style="width: 112px;height: 112px;">
								<div style="margin-top: 12px; text-align: center;font-size: 14px;">
									合作
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					style="width: 1px; height: 183px; background-color: #414141; transform:scaleX(1);margin-top: 27px;position: relative;left: -3px;">
				</div>
				<div class="right" style="margin-left: 10px;">
					<div class="desc" style="color: rgba(179,179,179,1);font-family: MicrosoftYaHei;">
						<div style="position: relative;top: 18px;color: rgba(179,179,179,1);">
							<div style="font-size: 12px; line-height: 22px;">
								中华易经网是黑龙江星尘互联网科技有限公司旗下品牌，传统
							</div>
							<div style="font-size: 12px; line-height: 22px;">
								吉祥文化企业，致力于中国吉祥文化和吉祥饰品的传播和推广。
							</div>
							<div style="font-size: 12px; line-height: 22px;">
								企业文化：感恩生命、关爱生活、圆满人生；
							</div>
							<div style="font-size: 12px; line-height: 22px;">
								企业目标：求真务实、自主创新、服务市场、共创双赢；
							</div>
							<div style="font-size: 12px; line-height: 22px;">
								企业方针：用高科技铸造知名品牌、不断进取、精益求精；
							</div>
							<div style="font-size: 12px; line-height: 22px;">
								企业理念：以创新求发展、以诚信为根本、以质量为生命。
							</div>
							<div style="font-size: 12px; line-height: 22px;">
								仅供休闲娱乐，请勿迷信。
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="contact">
			<div class="content" style="color: rgba(159,159,159);">
				<span style="margin-left: 5px; ">Copyright © 2006-2023 www.ydxc.com 版权所有</span>
				<a href="https://www.beian.gov.cn/portal/index.do"
					style="color:rgba(159,159,159) ;text-decoration: none;"><span
						style="margin-left: 35px; margin-right: 25px; display: flex; align-items: center;">
						<span class="jinghui"></span>公安网备案22017202000383号
					</span></a>
				<a href="https://beian.miit.gov.cn/"
					style="color:rgba(159,159,159) ;text-decoration: none;">工信部域名备案：黑ICP备2023006890号</a>
			</div>
		</div>
	</footer>
</template>

<script>
import {
	defineComponent,
	reactive
} from 'vue'

export default defineComponent({
	name: 'my-footer',
	setup() {

		const state = reactive({
			path: process.env.VUE_APP_FILE_PATH + '/'
		})
		return {
			state: state
		}
	}
})
</script>

<style>
footer {
	width: 100%;
	height: 275px;
}

footer .navigation {
	height: 230px;
	background: #333333;
}

footer .navigation .content {
	height: 230px;
	width: 1100px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

footer .navigation .content .left {
	width: 260px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

footer .navigation .content .left .logo {
	width: 220px;
	height: 85px;
	background: url("../../public/images/yidaixingchen.png") no-repeat;
	background-size: contain;
	margin-top: 10px;
	position: relative;
	left: -10px;
}

footer .navigation .content .middle {
	flex-grow: 1;
	color: rgb(179, 179, 179);
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 16px;
	padding-top: 52px;
}

footer .navigation .content .middle-content {
	display: flex;
	justify-content: center;
}

footer .navigation .content .middle-content .item {
	width: 147px;
	display: flex;
	justify-content: center;
}

footer .navigation .content .right {

	width: 380px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

footer .navigation .content .right .desc {
	width: 350px;
	color: white;
	text-align: left;
	margin-left: 35px;
	position: relative;
	bottom: 12px;
}

footer .navigation .content .right .desc div {
	line-height: 16px;
}
footer .navigation .content .right .desc div div{
	height: 22px;
}
footer .contact {
	background: #292929;
	height: 45px;
}

footer .contact .content {
	width: 1100px;
	height: 45px;
	margin-left: auto;
	margin-right: auto;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
}

footer .contact .content .jinghui {
	display: inline-block;
	width: 20px;
	height: 20px;
	background: url('../../public/images/jinghui.png') no-repeat;
	background-size: contain;
	margin-right: 8px;
}
</style>