import { service } from "./axios.js";

export const page = function (params) {
  return service.post("/open/class/page", params);
};

export const getbyId = function (params) {
  return service.get(`/open/class/getById?id=${params}`, params);
};

export const randomclass = function (params) {
  return service.post(`/open/class/random`, params);
};
