<template>
	<div class="my-xiaohuangli">
		<span style="position: relative;top: 30px;">农历{{state.huang.lunarMonth}}月{{state.huang.lunarDay}}</span>
		<div class="xiaoYi">
			<div class="div">
				宜
			</div>
			<span class="text">
				<template v-for="(item,index) in state.huang.yi" :key="index">
					{{item}}&nbsp;
				</template>
			</span>
		</div>
		<div class="xiaoJi">
			<div class="div">
				忌
			</div>
			<span class="text">
				<template v-for="(item,index) in state.huang.ji" :key="index">
					{{item}}&nbsp;
				</template>
			</span>
		</div>
		<div
			style="padding-left: 2px;box-sizing: border-box; width: 62px;height: 22px;box-sizing: border-box;color: #e64b39;border: 1px #e64b39 solid;text-align: center;line-height: 20px;font-size: 12px;margin: 0 auto;margin-top: 10px;">
			查看运势</div>
	</div>
</template>

<script>
	import {
		getHL
	} from '../api/paipan.js'
	// import {
	// 	useRouter
	// } from 'vue-router'

	import {
		lunarMonthes,
		lunarDates
	} from '../data/lunar.js'
	import {
		defineComponent,
		reactive
	} from 'vue'

	export default defineComponent({

		name: 'my-xiaohuangli',
		setup() {

			const state = reactive({
				huangParam: {
					"dateParam": ''
				},
				year: '',
				month: '',
				day: '',
				lunarMonth: '',
				lunarDate: '',
				huang: {},
				hl: false,
				index: 0,
				nl: ""
			})

			let date = new Date()
			let year = date.getFullYear()
			let month = date.getMonth() + 1
			let day = date.getDate()
			state.year = year
			state.month = month
			state.day = day
			let monthStr = month
			let dayStr = day
			if (month <= 9) {
				monthStr = 0 + '' + month
			}
			if (day <= 9) {
				dayStr = 0 + '' + day
			}
			state.huangParam.dateParam = year + '-' + monthStr + '-' + dayStr + " " + "00:00:00"
			for (let i = 0; i < lunarMonthes.length; i++) {
				if (lunarMonthes[i].code == state.month) {
					state.lunarMonth = lunarMonthes[i].name
					break
				}
			}

			for (let i = 0; i < lunarDates.length; i++) {
				if (lunarDates[i].code == state.day) {
					state.lunarDate = lunarDates[i].name
					break
				}
			}

			// let {
			// 	push
			// } = useRouter()



			const getLaoHuangLiApiSuccess = function(result) {
				if (result.status == 200 && result.data.status == 1) {
					state.huang = result.data.data
					console.log(state.huang);
				}
			}
			const getLaoHuangLiApiError = function() {

			}

			const initHuangLi = function() {
				getHL(state.huangParam.dateParam)
					.then(getLaoHuangLiApiSuccess).catch(getLaoHuangLiApiError)
			}




			initHuangLi()



			return {
				state: state,
			}
		}
	})
</script>

<style>
	.my-xiaohuangli {
		width: 165px;
		height: 170px;
		background-image: url('../../public/images/xiaohuangli-bg.png');
		cursor: pointer;
	}

	.my-xiaohuangli:hover {
		width: 165px;
		height: 170px;
		background-image: url('../../public/images/xiaohuangli-bg1.png');
		cursor: pointer;
	}

	.xiaoYi {
		margin-top: 43px;
		width: 100%;
		box-sizing: border-box;
		padding-left: 16px;
		padding-right: 28px;
		font-size: 12px;
		color: #ff3333;
		font-weight: 200;
	}

	.xiaoYi .div {
		width: 18px;
		height: 18px;
		border: 1px solid #e64b39;
		text-align: center;
		line-height: 17px;
		float: left;
	}

	.xiaoJi .div {
		width: 18px;
		height: 18px;
		border: 1px solid #1f1f1f;
		text-align: center;
		line-height: 17px;
		float: left;
	}

	.xiaoJi {
		width: 100%;
		box-sizing: border-box;
		padding-left: 16px;
		padding-right: 28px;
		font-size: 12px;
		color: #252525;
		font-weight: 200;
	}

	.text {
		margin-left: 5px;
		color: #252525;
		font-weight: 400;
		width: 96px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: -webkit-box;
		height: 18px;
		display: inline-block;
		text-align: left;
	}
</style>