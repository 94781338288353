
import { service } from './axios.js'
// 获取个人消息列表
export const commentMime = function (params) {
	return service.post('/comment/mime', params)
}
// 设置为已读
export const commentRead = function (params) {
	return service.post('/comment/read', params)
}
// 轮训支付结果
export const orderStatus = function (params) {
	return service.post('/open/order/status', params)
}

// 账户余额明细
export const amount = function (params) {
	return service.post('/account/amount', params)
}
// 账户余额明细
export const recordPage = function (params) {
	return service.post('/account/record/page', params)
}


// 提现
export const withdrawal = function (params) {
	return service.post('/wxPay/withdrawal', params)
}


// 解绑微信
export const wxUnbind = function (params) {
	return service.post('/buserinfo/wx/unbind', params)
}