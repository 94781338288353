import axios from "axios";

let baseURL = "";

let devBaseURL = process.env.VUE_APP_DEV_WEB_PATH;

if (process.env.VUE_APP_PRO_PATTERN == "external") {
  baseURL = window.config.apiPath;
} else {
  baseURL = process.env.VUE_APP_API_PATH;
}

export const service = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  responseType: "json",
  withCredentials: true,
});

export const devService = axios.create({
  baseURL: devBaseURL,
  timeout: 10000,
  responseType: "json",
  withCredentials: true,
});
// axios.interceptors.request.use(
//   function () {
//     response.setHeader("Cache-control", "no-cache");
//     response.setHeader("pragma", "no-cache");
//     response.setDateHeader("expires", -1);
//   },
//   function (error) {
//     // 对请求错误做些什么
//     return Promise.reject(error);
//   }
// );
