import SessionStorage from "../common/scripts/session-storage.js";
import { defineAsyncComponent } from "vue";
import {
  createRouter,
  // createWebHistory
  createWebHashHistory,
} from "vue-router";

import routeConfig from "./route-config.js";
import Home from "../views/home/home.vue";
import Sport from "../views/home/sports.vue";
// import Result from '../views/home/result.vue'
const result = defineAsyncComponent(() => import("../views/home/result.vue"));
// import register from '../views/register/index.vue'
const register = defineAsyncComponent(() => import("../views/register/index.vue"));
// import registerIndex from '../views/register/register.vue'
const registerIndex = defineAsyncComponent(() => import("../views/register/register.vue"));
// import registerFail from '../views/register/fail.vue'
const registerFail = defineAsyncComponent(() => import("../views/register/fail.vue"));
// import registerSuccess from '../views/register/success.vue'
const registerSuccess = defineAsyncComponent(() => import("../views/register/success.vue"));
// import registerSuccess from '../views/register/success.vue'
const bindEmail = defineAsyncComponent(() => import("../views/register/bind-email.vue"));
// import login from '../views/register/login.vue'
const login = defineAsyncComponent(() => import("../views/register/login.vue"));
// import findPassword from '../views/register/find-password.vue'
const findPassword = defineAsyncComponent(() => import("../views/register/find-password.vue"));

// import forgetPassword from '../views/register/forget-password.vue'
const forgetPassword = defineAsyncComponent(() => import("../views/register/forget-password.vue"));

// import passwordSuccess from '../views/register/password-success.vue'
const passwordSuccess = defineAsyncComponent(() => import("../views/register/password-success.vue"));
// import person from '../views/person/index.vue'
const person = defineAsyncComponent(() => import("../views/person/index.vue"));
// import orders from '../views/person/orders.vue'
const orders = defineAsyncComponent(() => import("../views/person/orders.vue"));
// import info from '../views/person/info.vue'
const info = defineAsyncComponent(() => import("../views/person/info.vue"));
// import news from '../views/news/index.vue'
const news = defineAsyncComponent(() => import("../views/news/index.vue"));
// import newsInfo from '../views/news/info.vue'
const newsInfo = defineAsyncComponent(() => import("../views/news/info.vue"));
// import newsList from '../views/news/list.vue'
const newsList = defineAsyncComponent(() => import("../views/news/list.vue"));
// import report from '../views/report/index.vue'
const report = defineAsyncComponent(() => import("../views/report/index.vue"));
// import reportInfo from '../views/report/info.vue'
const reportInfo = defineAsyncComponent(() => import("../views/report/info.vue"));
// import reportResult from '../views/report/result.vue'
const reportResult = defineAsyncComponent(() => import("../views/report/result.vue"));
const crapeResult = defineAsyncComponent(() => import("../views/ziwei/result.vue"));
const crape = defineAsyncComponent(() => import("../views/ziwei/index.vue"));
const crapeInfo = defineAsyncComponent(() => import("../views/ziwei/info.vue"));

const name = defineAsyncComponent(() => import("../views/namePair/index.vue"));

const reverse = defineAsyncComponent(() => import("../views/reverse/index.vue"));
const reverseInfo = defineAsyncComponent(() => import("../views/reverse/info.vue"));
const reverseResult = defineAsyncComponent(() => import("../views/reverse/result.vue"));

// 抽签
const chouqian = defineAsyncComponent(() => import("../views/chouqian/index.vue"));
// 观音
const chouqiangy = defineAsyncComponent(() => import("../views/chouqian/components/guanyin.vue"));
// 佛祖
const chouqianfz = defineAsyncComponent(() => import("../views/chouqian/components/fozu.vue"));
// 月老
const chouqianyl = defineAsyncComponent(() => import("../views/chouqian/components/yuelao.vue"));
// 关帝
const chouqiangd = defineAsyncComponent(() => import("../views/chouqian/components/guandi.vue"));
// 黄大仙
const chouqianhdx = defineAsyncComponent(() => import("../views/chouqian/components/huangdaxian.vue"));
// 吕祖
const chouqianhlz = defineAsyncComponent(() => import("../views/chouqian/components/lvzu.vue"));
// 妈祖
const chouqianhmz = defineAsyncComponent(() => import("../views/chouqian/components/mazu.vue"));
// 财神
const chouqiancs = defineAsyncComponent(() => import("../views/chouqian/components/caishen.vue"));
// 太上老君
const chouqiantslj = defineAsyncComponent(() => import("../views/chouqian/components/taishanglaojun.vue"));
// 文殊菩萨
const chouqianwsps = defineAsyncComponent(() => import("../views/chouqian/components/wenshupusa.vue"));

const qimengdunjia = defineAsyncComponent(() => import("../views/qimendunjia/index.vue"));
const qmdjInfo = defineAsyncComponent(() => import("../views/qimendunjia/info.vue"));
const qmdjResult = defineAsyncComponent(() => import("../views/qimendunjia/result.vue"));

const liuyao = defineAsyncComponent(() => import("../views/liuyao/index.vue"));
const liuyaoInfo = defineAsyncComponent(() => import("../views/liuyao/info.vue"));
const liuyaoResult = defineAsyncComponent(() => import("../views/liuyao/result.vue"));

const nameInfo = defineAsyncComponent(() => import("../views/namePair/info.vue"));
const nameResult = defineAsyncComponent(() => import("../views/namePair/result.vue"));
const meihua = defineAsyncComponent(() => import("../views/meihua/index.vue"));
const meihuaInfo = defineAsyncComponent(() => import("../views/meihua/info.vue"));
const meihuaResult = defineAsyncComponent(() => import("../views/meihua/result.vue"));

const liuren = defineAsyncComponent(() => import("../views/daliuren/index.vue"));
const liurenInfo = defineAsyncComponent(() => import("../views/daliuren/info.vue"));
const liurenResult = defineAsyncComponent(() => import("../views/daliuren/result.vue"));

const chenggu = defineAsyncComponent(() => import("../views/chenggu/index.vue"));
const chengguInfo = defineAsyncComponent(() => import("../views/chenggu/info.vue"));
const chengguResult = defineAsyncComponent(() => import("../views/chenggu/result.vue"));

const huangli = defineAsyncComponent(() => import("../views/laohuangli/index.vue"));
const huangliinfo = defineAsyncComponent(() => import("../views/laohuangli/info.vue"));

// 推广
const tuiguang = defineAsyncComponent(() => import("../views/tuiguang/index.vue"));

// 五行八字
const bazi1 = defineAsyncComponent(() => import("../views/report/components/report/free.vue"));
// 性格特征
// const xingge = defineAsyncComponent(() => import("../views/report/components/report/xingge.vue"));
// // 财运解析
// const caiyun = defineAsyncComponent(() => import("../views/report/components/report/money.vue"));
// // 事业解析
// const shiye = defineAsyncComponent(() => import("../views/report/components/report/shiye.vue"));
// // 婚姻情感
// const qinggan = defineAsyncComponent(() => import("../views/report/components/report/marriage.vue"));
// // 健康解析
// const jiankang = defineAsyncComponent(() => import("../views/report/components/report/health.vue"));
// // 六亲论述
// const liuqing = defineAsyncComponent(() => import("../views/report/components/report/liu.vue"));
// // 大运流年
// const liunina = defineAsyncComponent(() => import("../views/report/components/report/dayun.vue"));

// 八字排盘
const bazipaipan = defineAsyncComponent(() => import("../views/bazipaipan/index.vue"));
// 八字排盘页
const bazipaipanInfo = defineAsyncComponent(() => import("../views/bazipaipan/info.vue"));
// 八字报告页
const bazipaipanResult = defineAsyncComponent(() => import("../views/bazipaipan/result.vue"));
// 神煞示意
const shenshaResult = defineAsyncComponent(() => import("../views/bazipaipan/shensha.vue"));
// 神煞示意
const tuijianResult = defineAsyncComponent(() => import("../views/bazipaipan/tuijian.vue"));

// 单项整批
const danxiangzhengpi = defineAsyncComponent(() => import("../views/danxiang/index.vue"));
// 单项
const danxiangInfo = defineAsyncComponent(() => import("../views/danxiang/danxiang/info.vue"));
// 事业批卜
const shiyeResult = defineAsyncComponent(() => import("../views/danxiang/danxiang/shiye/result.vue"));
// 财运批卜
const caiyunResult = defineAsyncComponent(() => import("../views/danxiang/danxiang/caiyun/result.vue"));
// 婚姻批卜
const hunyinResult = defineAsyncComponent(() => import("../views/danxiang/danxiang/hunyin/result.vue"));
// 健康批卜
const jiankangResult = defineAsyncComponent(() => import("../views/danxiang/danxiang/jiankang/result.vue"));
// 命理手稿
const shougaoResult = defineAsyncComponent(() => import("../views/danxiang/danxiang/shougao/result.vue"));
// 周易合婚
const hehunResult = defineAsyncComponent(() => import("../views/danxiang/danxiang/hehun/result.vue"));
// 起名服务
const qimingResult = defineAsyncComponent(() => import("../views/danxiang/danxiang/qiming/result.vue"));
// 择日服务
const zeriResult = defineAsyncComponent(() => import("../views/danxiang/danxiang/zeri/result.vue"));
// 命局服务
const mingjuResult = defineAsyncComponent(() => import("../views/danxiang/danxiang/mingju/result.vue"));
// 支付
const zhifuResult = defineAsyncComponent(() => import("../views/danxiang/result.vue"));
// 结果
const jieguoResult = defineAsyncComponent(() => import("../views/danxiang/jieguo.vue"));

// 暂时废弃
// 整批
const zhengpiInfo = defineAsyncComponent(() => import("../views/danxiang/zhengpi/info.vue"));
// 文字整批
const weiziResult = defineAsyncComponent(() => import("../views/danxiang/zhengpi/wenzi/result.vue"));
// 电话整批
const dianhuaResult = defineAsyncComponent(() => import("../views/danxiang/zhengpi/dianhua/result.vue"));

// 个人中心
const geren = defineAsyncComponent(() => import("../views/geren/index.vue"));
// 会员
const huiyuanResult = defineAsyncComponent(() => import("../views/geren/huiyuan.vue"));
// 充值
const chongzhiResult = defineAsyncComponent(() => import("../views/geren/chongzhi.vue"));
// 订单
const dingdanResult = defineAsyncComponent(() => import("../views/geren/dingdan.vue"));
// 个人设置
const shezhiResult = defineAsyncComponent(() => import("../views/geren/gerenshezhi.vue"));
// 消息页面
const xiaoxiResult = defineAsyncComponent(() => import("../views/geren/xiaoxi.vue"));
// 余额页
const yuerResult = defineAsyncComponent(() => import("../views/geren/yuer.vue"));
// 提现页
const tixianResult = defineAsyncComponent(() => import("../views/geren/tixian.vue"));
// 提现结果
const txjieguoResult = defineAsyncComponent(() => import("../views/geren/tixianjieguo.vue"));
// 充值结果
const czjieguoResult = defineAsyncComponent(() => import("../views/geren/chongzhijieguo.vue"));

const WithdrawalError = defineAsyncComponent(() => import("../views/geren/WithdrawalError.vue"));

//知识
const zhishi = defineAsyncComponent(() => import("../views/zhishi/index.vue"));
const zhishiInfo = defineAsyncComponent(() => import("../views/zhishi/info.vue"));

//古籍
const guji = defineAsyncComponent(() => import("../views/guji/index.vue"));
const gujiInfo = defineAsyncComponent(() => import("../views/guji/guji_info.vue"));
const gujiList = defineAsyncComponent(() => import("../views/guji/guji_list.vue"));

// 名人案例
const mingreng = defineAsyncComponent(() => import("../views/mingren/index.vue"));
// 名人选择页
const mingrengInfo = defineAsyncComponent(() => import("../views/mingren/info.vue"));
// 名人报告页
const mingrengResult = defineAsyncComponent(() => import("../views/mingren/result.vue"));

// 协议
const xieyi = defineAsyncComponent(() => import("../views/xieyi/index.vue"));
// 用户协议
const yinghuxieyi = defineAsyncComponent(() => import("../views/xieyi/yonghu.vue"));
// 隐私协议
const yinsixieyi = defineAsyncComponent(() => import("../views/xieyi/yinsi.vue"));

//论坛发布求测信息
const fabuIndex = defineAsyncComponent(() => import("../views/gerenqiuce/index.vue"));
const fabuqiuce = defineAsyncComponent(() => import("../views/gerenqiuce/fabuqiuce.vue"));
const fabuSuccess = defineAsyncComponent(() => import("../views/gerenqiuce/fabuSuccess.vue"));

//求测大厅
const qiuceIndex = defineAsyncComponent(() => import("../views/luntan/index.vue"));
const dating = defineAsyncComponent(() => import("../views/luntan/dating.vue"));
const yincangtishi = defineAsyncComponent(() => import("../views/luntan/yincangtishi.vue"));
const luntanpay = defineAsyncComponent(() => import("../views/luntan/luntanpay.vue"));
const luntanInfo = defineAsyncComponent(() => import("../views/luntan/luntanInfo.vue"));

const cezi = defineAsyncComponent(() => import("../views/cezi/index.vue"));
const ceziInfo = defineAsyncComponent(() => import("../views/cezi/info.vue"));
const ceziResult = defineAsyncComponent(() => import("../views/cezi/result.vue"));

//视频
const videoIndex = defineAsyncComponent(() => import("../views/shipinbankuai/index.vue"));
const shipinlist = defineAsyncComponent(() => import("../views/shipinbankuai/videoList.vue"));

//视频详情
const videoInfoIndex = defineAsyncComponent(() => import("../views/videoInfo/index.vue"));
const videoInfo = defineAsyncComponent(() => import("../views/videoInfo/videoInfo.vue"));

// 六道轮回
const liudao = defineAsyncComponent(() => import("../views/liudaolunhui/index.vue"));
// 六道轮回排盘
const liudaoInfo = defineAsyncComponent(() => import("../views/liudaolunhui/info.vue"));
// 六道轮回报告
const liudaoResult = defineAsyncComponent(() => import("../views/liudaolunhui/result.vue"));

// 单独八字排盘
const bazi = defineAsyncComponent(() => import("../views/danbazi/index.vue"));
// 八字排盘页
const baziInfo = defineAsyncComponent(() => import("../views/danbazi/info.vue"));
// 八字报告页
const baziResult = defineAsyncComponent(() => import("../views/danbazi/result.vue"));
// 神煞示意
const shensha = defineAsyncComponent(() => import("../views/danbazi/shensha.vue"));
// 推荐书籍
const tuijian = defineAsyncComponent(() => import("../views/danbazi/tuijian.vue"));

//大师认证
const answer = defineAsyncComponent(() => import("../views/answer/index.vue"));
const answerInfo = defineAsyncComponent(() => import("../views/answer/answerInfo.vue"));
// 命理答题活动
const answerindx = defineAsyncComponent(() => import("../views/answer/index.vue"));
// 命理答题活动
const answeractive = defineAsyncComponent(() => import("../views/answer/answeractive.vue"));
// 命理排行榜
const answercharts = defineAsyncComponent(() => import("../views/answer/charts.vue"));

//桃花
const taohua = defineAsyncComponent(() => import("../views/taohua/index.vue"));
const taohuareport = defineAsyncComponent(() => import("../views/taohua/report.vue"));
const taoHuaResult = defineAsyncComponent(() => import("../views/taohua/result.vue"));

//姓名测字
const nameindex = defineAsyncComponent(() => import("../views/name/index.vue"));
const namereport = defineAsyncComponent(() => import("../views/name/report.vue"));
const nameresult = defineAsyncComponent(() => import("../views/name/result.vue"));

//赠礼活动
const gift = defineAsyncComponent(() => import("../views/activity/gift.vue"));

//存档
const cundang = defineAsyncComponent(() => import("../views/cundang/index.vue"));
const cundangList = defineAsyncComponent(() => import("../views/cundang/list.vue"));

//测试
const test = defineAsyncComponent(() => import("../views/test/index.vue"));
const enterFun = function (to, from, next) {
  SessionStorage.get("user-info")
    .then(function (result) {
      if (!result) {
        next("/register/login");
      } else {
        next();
      }
    })
    .catch(function () {
      next("/register/login");
    });
};

const routes = [
  {
    path: "/test",
    name: "test",
    component: test,
  },
  {
    path: "/cundang",
    name: "cundang",
    component: cundang,
    children: [
      {
        path: "/cundangList",
        name: "cundangList",
        component: cundangList,
      },
    ],
  },
  {
    path: "/gift",
    name: "gift",
    component: gift,
  },
  {
    path: "/videoInfo",
    name: "videoInfo",
    component: videoInfoIndex,
    children: [
      {
        path: "/video/info",
        name: "videoInfo",
        component: videoInfo,
      },
    ],
  },
  {
    path: "/shipin",
    name: "videoIndex",
    component: videoIndex,
    children: [
      {
        path: "/shipin/list",
        name: "shipinlist",
        component: shipinlist,
      },
    ],
  },
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/sport",
    name: "sport",
    component: Sport,
  },
  {
    path: "/result",
    name: "result",
    component: result,
  },
  {
    path: "/person",
    name: "person",
    component: person,
    children: [
      {
        path: "/person/orders",
        name: "orders",
        component: orders,
        // beforeEnter: enterFun,
      },
      {
        path: "/person/info",
        name: "info",
        component: info,
        // beforeEnter: enterFun,
      },
    ],
  },
  {
    path: "/register",
    name: "register",
    component: register,
    children: [
      {
        path: "/register/register-fail",
        name: "register-fail",
        component: registerFail,
        // beforeEnter: enterFun,
      },
      {
        path: "/register/register-success",
        name: "register-success",
        component: registerSuccess,
        // beforeEnter: enterFun,
      },
      {
        path: "/register/login",
        name: "login",
        component: login,
      },
      {
        path: "/register/find-password",
        name: "find-password",
        component: findPassword,
      },
      {
        path: "/register/password-success",
        name: "password-success",
        component: passwordSuccess,
        // beforeEnter: enterFun,
      },
      {
        path: "/register/forget-password",
        name: "forget-password",
        component: forgetPassword,
        // beforeEnter: enterFun
      },
      {
        path: "/register/index",
        name: "register-index",
        component: registerIndex,
      },
      {
        path: "/register/bind-email",
        name: "bind-email",
        component: bindEmail,
        beforeEnter: enterFun,
      },
    ],
  },
  {
    path: "/news",
    name: "news",
    component: news,
    children: [
      {
        path: "/news/info",
        name: "news-info",
        component: newsInfo,
      },
      {
        path: "/news/list",
        name: "news-list",
        component: newsList,
      },
    ],
  },
  {
    path: "/report",
    name: "report",
    component: report,
    children: [
      {
        path: "/report/info",
        name: "report-info",
        component: reportInfo,
      },
      {
        path: "/report/result",
        name: "report-result",
        component: reportResult,
        children: [
          {
            path: "/report/result/bazi",
            name: "report-result-bazi",
            component: bazi1,
          },
          // {
          // 	path: "/report/result/xingge",
          // 	name: "report-result-xingge",
          // 	component: xingge,
          // },
          // {
          // 	path: "/report/result/caiyun",
          // 	name: "report-result-caiyun",
          // 	component: caiyun,
          // },
          // {
          // 	path: "/report/result/shiye",
          // 	name: "report-result-shiye",
          // 	component: shiye,
          // },
          // {
          // 	path: "/report/result/qinggan",
          // 	name: "report-result-qinggan",
          // 	component: qinggan,
          // },
          // {
          // 	path: "/report/result/jiankang",
          // 	name: "report-result-jiankang",
          // 	component: jiankang,
          // },
          // {
          // 	path: "/report/result/liuqing",
          // 	name: "report-result-liuqing",
          // 	component: liuqing,
          // },
          // {
          // 	path: "/report/result/liunina",
          // 	name: "report-result-liunina",
          // 	component: liunina,
          // },
        ],
      },
    ],
  },
  {
    path: "/crape",
    name: "crape",
    component: crape,
    children: [
      {
        path: "/crape/info",
        name: "crape-info",
        component: crapeInfo,
      },
      {
        path: "/crape/result",
        name: "crape-result",
        component: crapeResult,
      },
    ],
  },
  {
    path: "/name",
    name: "name",
    component: name,
    children: [
      {
        path: "/name/info",
        name: "name-info",
        component: nameInfo,
      },
      {
        path: "/name/result",
        name: "name-result",
        component: nameResult,
      },
    ],
  },
  {
    path: "/reverse",
    name: "reverse",
    component: reverse,
    children: [
      {
        path: "/reverse/info",
        name: "reverse-info",
        component: reverseInfo,
      },
      {
        path: "/reverse/result",
        name: "reverse-result",
        component: reverseResult,
      },
    ],
  },
  {
    path: "/chouqian",
    name: "chouqian",
    component: chouqian,
    children: [
      {
        path: "/chouqian/guanyin",
        name: "chouqian-guanyin",
        component: chouqiangy,
      },
      {
        path: "/chouqian/fozu",
        name: "chouqian-fozu",
        component: chouqianfz,
      },
      {
        path: "/chouqian/yuelao",
        name: "chouqian-yuelao",
        component: chouqianyl,
      },
      {
        path: "/chouqian/guandi",
        name: "chouqian-guandi",
        component: chouqiangd,
      },
      {
        path: "/chouqian/huangdaxian",
        name: "chouqian-huangdaxian",
        component: chouqianhdx,
      },
      {
        path: "/chouqian/lvzu",
        name: "chouqian-lvzu",
        component: chouqianhlz,
      },
      {
        path: "/chouqian/mazu",
        name: "chouqian-mazu",
        component: chouqianhmz,
      },
      {
        path: "/chouqian/caishen",
        name: "chouqian-caishen",
        component: chouqiancs,
      },
      {
        path: "/chouqian/taishanglaojun",
        name: "chouqian-taishanglaojun",
        component: chouqiantslj,
      },
      {
        path: "/chouqian/wenshupusa",
        name: "chouqian-wenshupusa",
        component: chouqianwsps,
      },
    ],
  },
  {
    path: "/qimendunjia",
    name: "qimendunjia",
    component: qimengdunjia,
    children: [
      {
        path: "/qimendunjia/info",
        name: "qimendunjia-info",
        component: qmdjInfo,
      },
      {
        path: "/qimendunjia/result",
        name: "qimendunjia-result",
        component: qmdjResult,
      },
    ],
  },
  {
    path: "/liuyao",
    name: "liuyao",
    component: liuyao,
    children: [
      {
        path: "/liuyao/info",
        name: "liuyao-info",
        component: liuyaoInfo,
      },
      {
        path: "/liuyao/result",
        name: "liuyao-result",
        component: liuyaoResult,
      },
    ],
  },
  {
    path: "/meihua",
    name: "meihua",
    component: meihua,
    children: [
      {
        path: "/meihua/info",
        name: "meihua-info",
        component: meihuaInfo,
      },
      {
        path: "/meihua/result",
        name: "meihua-result",
        component: meihuaResult,
      },
    ],
  },
  {
    path: "/liuren",
    name: "liuren",
    component: liuren,
    children: [
      {
        path: "/liuren/info",
        name: "liuren-info",
        component: liurenInfo,
      },
      {
        path: "/liuren/result",
        name: "liuren-result",
        component: liurenResult,
      },
    ],
  },
  {
    path: "/chenggu",
    name: "chenggu",
    component: chenggu,
    children: [
      {
        path: "/chenggu/info",
        name: "chenggu-info",
        component: chengguInfo,
      },
      {
        path: "/chenggu/result",
        name: "chenggu-result",
        component: chengguResult,
      },
    ],
  },
  {
    path: "/huangli",
    name: "huangli",
    component: huangli,
    children: [
      {
        path: "/huangli/info",
        name: "huangli-info",
        component: huangliinfo,
      },
    ],
  },
  {
    path: "/tuiguang",
    name: "tuiguang",
    component: tuiguang,
  },
  {
    path: "/bazipaipan",
    name: "bazipaipan",
    component: bazipaipan,
    children: [
      {
        path: "/bazipaipan/info",
        name: "bazipaipan-info",
        component: bazipaipanInfo,
      },
      {
        path: "/bazipaipan/result",
        name: "bazipaipan-result",
        component: bazipaipanResult,
      },
      {
        path: "/bazipaipan/shenshaResult",
        name: "bazipaipan-shenshaResult",
        component: shenshaResult,
      },
      {
        path: "/bazipaipan/tuijianResult",
        name: "bazipaipan-tuijianResult",
        component: tuijianResult,
      },
    ],
  },
  {
    path: "/danxiangzhengpi",
    name: "danxiangzhengpi",
    component: danxiangzhengpi,
    children: [
      {
        path: "/danxiang/info",
        name: "danxiang-info",
        component: danxiangInfo,
      },
      {
        path: "/zhengpi/info",
        name: "zhengpi-info",
        component: zhengpiInfo,
      },
      {
        path: "/danxiang/shiyeResult",
        name: "danxiang-shiyeResult",
        component: shiyeResult,
      },
      {
        path: "/danxiang/caiyunResult",
        name: "danxiang-caiyunResult",
        component: caiyunResult,
      },
      {
        path: "/danxiang/hunyinResult",
        name: "danxiang-hunyinResult",
        component: hunyinResult,
      },
      {
        path: "/danxiang/jiankangResult",
        name: "danxiang-jiankangResult",
        component: jiankangResult,
      },
      {
        path: "/danxiang/shougaoResult",
        name: "danxiang-shougaoResult",
        component: shougaoResult,
      },
      {
        path: "/danxiang/hehunResult",
        name: "danxiang-hehunResult",
        component: hehunResult,
      },
      {
        path: "/danxiang/qimingResult",
        name: "danxiang-qimingResult",
        component: qimingResult,
      },
      {
        path: "/danxiang/zeriResult",
        name: "danxiang",
        component: zeriResult,
      },
      {
        path: "/zhifuResult",
        name: "zhifuResult",
        component: zhifuResult,
      },
      {
        path: "/jieguoResult",
        name: "jieguoResult",
        component: jieguoResult,
      },
      {
        path: "/zhengpi/weiziResult",
        name: "zhengpi-weiziResult",
        component: weiziResult,
      },
      {
        path: "/zhengpi/dianhuaResult",
        name: "zhengpi-dianhuaResult",
        component: dianhuaResult,
      },
      {
        path: "/danxiang/mingjuResult",
        name: "danxiang-mingjuResult",
        component: mingjuResult,
      },
    ],
  },
  {
    path: "/geren",
    name: "geren",
    component: geren,
    children: [
      {
        path: "/geren/huiyuanResult",
        name: "geren-huiyuanResult",
        component: huiyuanResult,
      },
      {
        path: "/geren/chongzhiResult",
        name: "geren-chongzhiResult",
        component: chongzhiResult,
      },
      {
        path: "/geren/dingdanResult",
        name: "geren-dingdanResult",
        component: dingdanResult,
      },
      {
        path: "/geren/shezhiResult",
        name: "geren-shezhiResult",
        component: shezhiResult,
      },
      {
        path: "/geren/xiaoxiResult",
        name: "geren-xiaoxiResult",
        component: xiaoxiResult,
      },
      {
        path: "/geren/yuerResult",
        name: "geren-yuerResult",
        component: yuerResult,
      },
      {
        path: "/geren/tixianResult",
        name: "geren-tixianResult",
        component: tixianResult,
      },
      {
        path: "/geren/txjieguoResult",
        name: "geren-txjieguoResult",
        component: txjieguoResult,
      },
      {
        path: "/geren/czjieguoResult",
        name: "geren-czjieguoResult",
        component: czjieguoResult,
      },
      {
        path: "/geren/WithdrawalError",
        name: "geren-WithdrawalError",
        component: WithdrawalError,
      },
      //   WithdrawalError,
    ],
  },
  {
    path: "/guji",
    name: "guji",
    component: guji,
    children: [
      {
        path: "/guji/info",
        name: "guji-info",
        component: gujiInfo,
      },
      {
        path: "/guji/list",
        name: "guji-list",
        component: gujiList,
      },
    ],
  },
  {
    path: "/zhishi",
    name: "zhishi",
    component: zhishi,
    children: [
      {
        path: "/zhishi/info",
        name: "zhishi-info",
        component: zhishiInfo,
      },
    ],
  },
  {
    path: "/mingreng",
    name: "mingreng",
    component: mingreng,
    children: [
      {
        path: "/mingreng/info",
        name: "mingreng-info",
        component: mingrengInfo,
      },
      {
        path: "/mingreng/result",
        name: "mingreng-Result",
        component: mingrengResult,
      },
    ],
  },
  {
    path: "/xieyi",
    name: "xieyi",
    component: xieyi,
    children: [
      {
        path: "/yinghuxieyi",
        name: "yinghuxieyi",
        component: yinghuxieyi,
      },
      {
        path: "/yinsixieyi",
        name: "yinsixieyi",
        component: yinsixieyi,
      },
    ],
  },
  {
    path: "/fabu",
    name: "fabuIndex",
    component: fabuIndex,
    children: [
      {
        path: "/fabu/fabuqiuce",
        name: "fabuqiuce",
        component: fabuqiuce,
      },
      {
        path: "/fabu/fabuSuccess",
        name: "fabuSuccess",
        component: fabuSuccess,
      },
    ],
  },
  //
  {
    path: "/qiuce",
    name: "qiuceIndex",
    component: qiuceIndex,
    children: [
      {
        path: "/qiuce/dating",
        name: "dating",
        component: dating,
      },
      {
        path: "/qiuce/yincangtishi",
        name: "yincangtishi",
        component: yincangtishi,
      },
      {
        path: "/qiuce/luntanpay",
        name: "luntanpay",
        component: luntanpay,
      },
      {
        path: "/qiuce/info",
        name: "luntanInfo",
        component: luntanInfo,
      },
    ],
  },

  {
    path: "/cezi",
    name: "cezi",
    component: cezi,
    children: [
      {
        path: "/cezi/info",
        name: "cezi-info",
        component: ceziInfo,
      },
      {
        path: "/cezi/result",
        name: "cezi-result",
        component: ceziResult,
      },
    ],
  },
  {
    path: "/liudao",
    name: "liudao",
    component: liudao,
    children: [
      {
        path: "/liudao/liudaoInfo",
        name: "liudao-liudaoInfo",
        component: liudaoInfo,
      },
      {
        path: "/liudao/liudaoResult",
        name: "liudao-liudaoResult",
        component: liudaoResult,
      },
    ],
  },
  {
    path: "/bazi",
    name: "bazi",
    component: bazi,
    children: [
      {
        path: "/bazi/baziInfo",
        name: "bazi-baziInfo",
        component: baziInfo,
      },
      {
        path: "/bazi/baziResult",
        name: "bazi-baziResult",
        component: baziResult,
      },
      {
        path: "/bazi/shensha",
        name: "bazi-shensha",
        component: shensha,
      },
      {
        path: "/bazi/tuijian",
        name: "bazi-tuijian",
        component: tuijian,
      },
    ],
  },
  {
    path: "/answer",
    name: "answer",
    component: answer,
    children: [
      {
        path: "/answerInfo",
        name: "answerInfo",
        component: answerInfo,
      },
      {
        path: "/answeractive",
        name: "answeractive",
        component: answeractive,
      },
    ],
  },

  {
    path: "/answerindx",
    name: "answerindx",
    component: answerindx,
    children: [
      {
        path: "/answercharts",
        name: "answercharts",
        component: answercharts,
      },
    ],
  },

  {
    path: "/nameindex",
    name: "nameindex",
    component: nameindex,
    children: [
      {
        path: "/namereport",
        name: "namereport",
        component: namereport,
      },
      {
        path: "/nameresult",
        name: "nameresult",
        component: nameresult,
      },
    ],
  },
  {
    path: "/taohua",
    name: "taohua",
    component: taohua,
    children: [
      {
        path: "/taohuareport",
        name: "taohuareport",
        component: taohuareport,
      },
      {
        path: "/taoHuaResult",
        name: "taoHuaResult",
        component: taoHuaResult,
      },
    ],
  },
];
// const router = createRouter({
// 	history: createWebHistory('/'),
// 	routes
// })

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.query.code) {
    SessionStorage.set("invite-code", to.query.code);
  }
  if (routeConfig.excepts.includes(to.path)) {
    next();
  } else {
    SessionStorage.get("paipan-date")
      .then(function (result) {
        if (result) {
          next();
        } else {
          next("/");
        }
      })
      .catch(function () {
        next("/");
      });
  }
});

export default router;
