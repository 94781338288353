<template>
	<div v-show="state.isShow" class="my-modal">
		<div class="tishi">
			<div class="top">
				提示
			</div>
			<div class="neirong">
				{{ state.msg }}
			</div>
			<div class="mlb">
				<button type="button" class="modelbtn" @click="close">确认</button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		defineComponent,
		reactive
	} from 'vue'
	import bus from '../common/scripts/bus.js'
	import {useRouter} from 'vue-router'
	export default defineComponent({
		name: 'my-modal',
		setup() {
			const router = useRouter()
			const state = reactive({
				title: '',
				msg: '',
				isShow: false,
				path:null
			})

			const modalShow = function(options) {
				state.title = options.title
				state.msg = options.msg
				state.isShow = true
				if (options.path) {
					state.path=options.path
				}
			}

			const initEvents = function() {
				bus.on('modal-show', modalShow)
			}

			const close = function() {
				state.isShow = false
				if (state.path != null) {
					router.push(state.path)
				}
			}

			initEvents()

			return {
				state: state,
				close: close
			}
		}
	})
</script>
<style scoped>
	.my-modal {
		font-family: 微软雅黑;
		position: fixed;
		z-index: 2000;
		width: 100%;
		height: 100%;
		background: rgba(33, 33, 33, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
		top: 0px;
	}

	.tishi {
		width: 484px;
		height: 324px;
		box-sizing: border-box;
		background-color: #ffffff;
		font-size: 18px;
		font-weight: 600;
		position: relative;
		padding-left: 32px;
		padding-right: 32px;
		padding-bottom: 40px;
	}

	.top {
		width: 100%;
		border-bottom: 1px solid #eaeaea;
		height: 95px;
		line-height: 95px;
		position: relative;
		font-weight: 500;
		position: relative;
	}

	.xuxian {
		width: 522px;
	}

	.guanbi {
		width: 14px;
		height: 14px;
		top: 41px;
		right: 0;
		position: absolute;
		cursor: pointer;
		background: url('../../public/images/guanbi.png');
	}

	.neirong {
		font-size: 16px;
		font-weight: 200;
		height: 147px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		justify-content: center;
		position: relative;
	}

	.btn {
		width: 99px;
		height: 36px;
		background-color: #E03229;
		/* border: 1px solid #B2271F; */
		/* border-radius: 8px; */
		background: url("../../public/images/que.png") no-repeat center center;
	}

	.modelbtn {
		width: 140px;
		height:40px;
		text-align: center;
		line-height: 38px;
		border: 1px solid #e64b39;
		background-color: #e64b39;
		color: #fff;
		font-size: 16px;
		font-weight: 200;
	}

	.mlb {
		width: 100%;
		height: 42px;
		display: flex;
		justify-content: center;
	}
</style>