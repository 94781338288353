<template>
  <div v-show="state.isShow" class="my-tishi">
    <div class="kuang" v-if="state.type != 2000">
      <div class="biaoti">
        提示
        <!-- <img src="../../public/images/guanbi.png"
					style="position: absolute;top: 41px;right: 0px;cursor: pointer;" @click="guanbi"> -->
      </div>
      <div class="neirong">
        <template v-if="state.type == '1'">
          <template v-if="state.jiebang == '1'"> 您确定解除当前绑定的微信吗 </template>
          <template v-if="state.jiebang == '2'"> 当前未绑手机号，暂不允许解除绑定 </template>
        </template>
        <template v-if="state.type == '2'">
          提现到绑定微信账户 <span style="color: #e64b39">￥{{ state.amount }}</span> 吗
        </template>
        <template v-if="state.type == '3'"> 请开通会员后观看！ </template>
        <template v-if="state.type == '4'">
          {{ state.amount }}
        </template>
        <template v-if="state.type == '5'">
          {{ state.amount }}
        </template>
        <template v-if="state.type == '999'">
          {{ state.title }}
        </template>
        <template v-if="state.type == '登录'">
          {{ state.title }}
        </template>
        <template v-if="state.type == '档案删除'">确定删除档案</template>
        <template v-if="state.type == '档案'"> 档案添加成功！ </template>
      </div>
      <div class="anniu">
        <template v-if="state.type == '4'">
          <div
            style="width: 139px; height: 42px; background-color: #e64b39; color: #fff; margin-right: 9px; line-height: 42px; cursor: pointer"
            @click="gobangding">去绑定</div>
          <div
            style="width: 139px; height: 42px; border: 1px solid #eaeaea; margin-left: 9px; line-height: 42px; cursor: pointer"
            @click="guanbi">取消</div>
        </template>
        <template v-else-if="state.type == '档案'">
          <div
            style="width: 139px; height: 42px; background-color: #e64b39; color: #fff; margin-right: 9px; line-height: 42px; cursor: pointer"
            @click="gocundang">去查看</div>
          <div
            style="width: 139px; height: 42px; border: 1px solid #eaeaea; margin-left: 9px; line-height: 42px; cursor: pointer"
            @click="guanbi">确定</div>
        </template>
        <template v-else-if="state.type == '登录'">
          <div
            style="width: 139px; height: 42px; border: 1px solid #eaeaea; margin-right: 9px;  line-height: 42px; cursor: pointer"
            @click="queding">确定</div>
          <div
            style="width: 139px; height: 42px; background-color: #e64b39; color: #fff;margin-left: 9px; line-height: 42px; cursor: pointer"
            @click="denglu">去登录</div>
        </template>
        <template v-else-if="state.type == '档案删除'">
          <div
            style="width: 139px; height: 42px; background-color: #e64b39; color: #fff; margin-right: 9px; line-height: 42px; cursor: pointer"
            @click="queding">取消</div>
          <div
            style="width: 139px; height: 42px; border: 1px solid #eaeaea; margin-left: 9px; line-height: 42px; cursor: pointer"
            @click="delrecord">确定删除</div>
        </template>
        <template v-else>
          <div
            style="width: 139px; height: 42px; background-color: #e64b39; color: #fff; margin-right: 9px; line-height: 42px; cursor: pointer"
            @click="queding">确定</div>
        </template>
      </div>
    </div>
    <div v-else-if="state.type == 2000" class="tixian2000">
      <div class="tixian2000header">实名认证</div>
      <div class="tixian2000body">根据国家相关政策要求，2000元以上提现，需要您完成实名 认证。未认证的用户将受到提现系统限制。你的信息将进行 严格的隐私保护</div>
      <div class="nameinput">
        <input type="text" placeholder="请输入提现微信实名姓名" v-model="state.name" />
        <div class="changename">姓名</div>
      </div>
      <div style="display: flex; justify-content: center; margin-top: 45px">
        <div
          style="width: 139px; height: 42px; background-color: #e64b39; color: #fff; margin-right: 9px; line-height: 42px; cursor: pointer"
          @click="tixian">确定</div>
        <div
          style="width: 139px; height: 42px; border: 1px solid #eaeaea; margin-left: 9px; line-height: 42px; cursor: pointer"
          @click="guanbi">取消</div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive } from "vue";
import bus from "../common/scripts/bus.js";
import { wxUnbind, withdrawal } from "../api/geren.js";
import { delRecord } from "../api/cundang.js";
import { useRouter } from "vue-router";
export default defineComponent({
  components: {},
  name: "my-tishi",
  setup() {
    let { push } = useRouter();
    const state = reactive({
      isShow: false,
      type: "",
      amount: "",
      jiebang: "",
      name: "",
      title: "",
      id: null,
    });
    const init = function () {
      bus.on("tishi-show", tishiShow);
    };
    const tishiShow = (options) => {
      state.isShow = true;
      console.log(options);
      state.type = options.type;
      state.amount = options.amount;
      state.jiebang = options.jiebang;
      state.title = options.title;
      state.id = options.id;
    };
    // 关闭弹框
    const guanbi = () => {
      state.isShow = false;
    };
    // 确实一些内容
    const queding = () => {
      if (state.type == "2") {
        if (state.amount < 2000) {
          let obj = {
            amount: state.amount,
          };
          withdrawal(obj).then((res) => {
            if (res.status == 200 && res.data.status == 1) {
              state.isShow = false;
              push("/geren/txjieguoResult");
            } else {
              state.isShow = false;
              push("/geren/WithdrawalError");
            }
          });
        } else {
          state.type = 2000;
        }
      } else if (state.type == "1" && state.jiebang == "1") {
        wxUnbind().then((res) => {
          if (res.status == 200 && res.data.status == 1) {
            location.reload();
          }
        });
      } else if (state.type == "3") {
        state.isShow = false;
        push("/geren/huiyuanResult");
      } else {
        state.isShow = false;
      }
    };
    const gobangding = () => {
      push("/geren/shezhiResult");
      state.isShow = false;
    };
    const tixian = () => {
      let obj = {
        amount: state.amount,
        name: state.name,
      };
      withdrawal(obj).then((res) => {
        if (res.status == 200 && res.data.status == 1) {
          state.isShow = false;
          push("/geren/txjieguoResult");
        } else {
          state.isShow = false;
          push("/geren/WithdrawalError");
        }
      });
    };

    const gocundang = () => {
      push("/cundangList");
    };

    const denglu = () => {
      state.isShow = false;
      bus.emit("denglu-show");
    };

    const delrecord = () => {
      delRecord({ id: state.id }).then(delSuccess).catch();
    };
    const delSuccess = (result) => {
      if (result.data.status == 1) {
        state.type = "999";
        state.title = "删除成功";
        setTimeout(() => {
          location.reload();
        }, 2000);
      } else {
        state.type = "999";
        state.title = result.data.msg;
      }
    };
    init();
    return {
      state: state,
      guanbi: guanbi,
      queding: queding,
      gobangding: gobangding,
      tixian,
      gocundang,
      denglu,
      delrecord,
    };
  },
});
</script>

<style>
.my-tishi {
  font-weight: 200;
  color: #252525;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 99;
}

.my-tishi .kuang {
  width: 484px;
  height: 324px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* margin-top: -242px;
		margin-left: -162px; */
  padding: 0px 32px;
}

.my-tishi .kuang .biaoti {
  height: 95px;
  border-bottom: 1px solid #eaeaea;
  font-size: 18px;
  line-height: 95px;
  position: relative;
}

.my-tishi .kuang .neirong {
  height: 147px;
  line-height: 147px;
}

.my-tishi .kuang .anniu {
  height: 82px;
  display: flex;
  justify-content: center;
}

.tixian2000 {
  width: 484px;
  height: 381px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.tixian2000 .tixian2000header {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.tixian2000 .tixian2000body {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 16px;
  font-weight: 500;
}

.tixian2000 .nameinput {
  width: 282px;
  height: 41px;
  border: 1px solid #eaeaea;
  margin: 45px auto 0;
  position: relative;
}

.nameinput>input {
  width: 100%;
  height: 100%;
  padding-left: 60px;
  border: none;
}

.nameinput>input:focus {
  border: none;
}

.nameinput .changename {
  position: absolute;
  left: 0;
  top: 0;
  width: 60px;
  height: 40px;
  line-height: 40px;
}
</style>
