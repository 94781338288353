<template>
  <!-- v-show="state.isShow" -->
  <div class="my-pay-modal" v-show="state.isShow">
    <div class="fantuimodel">
      <div class="fantuiheader">
        选择年份
        <img src="../../public/images/closebtn.png" alt="" class="closebtn" @click="quxiao" />
      </div>
      <div class="fantuibody">
        <div class="yearDivlist">
          <template v-for="(item, index) in state.userInfo.fantuibirthdatArr" :key="index">
            <div class="yearDiv" :class="{ activell: state.currentIndex === index }" @click="changeYear(item, index)">
              {{ item.year.toString().length < 4 ? "0" + `${item.year}` : item.year }}
            </div>
          </template>
        </div>
        <div class="fantuibtn" @click="paipan">确定</div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import { testPaipan } from "../api/paipan.js";
import SessionStorage from "../common/scripts/session-storage.js";
import bus from "../common/scripts/bus.js";
export default defineComponent({
  name: "my-querenMTK",
  setup() {
    const router = useRouter();

    const state = reactive({
      title: "",
      msg: "",
      isShow: false,
      userInfo: {},
      currentIndex: 0,
    });
    const modalShow = function (options) {
      state.title = options.title;
      state.currentIndex = 0;
      console.log(options);
      state.isShow = true;
      state.userInfo = options.option;
      let month = options.option.fantuibirthdatArr[0].month;
      let day = options.option.fantuibirthdatArr[0].day;
      let hour = options.option.fantuibirthdatArr[0].hour;
      let monthStr = month > 9 ? month : "0" + month;
      let dateStr = day > 9 ? day : "0" + day;
      let hourStr = hour > 9 ? hour : "0" + hour;
      state.userInfo.birthday = options.option.fantuibirthdatArr[0].year + "-" + monthStr + "-" + dateStr + " " + hourStr + ":00" + ":00";
      console.log(state.userInfo.birthday);
    };

    const close = function () {
      state.isShow = false;
    };

    const changeYear = function (item, index) {
      state.currentIndex = index;
      let month = item.month;
      let day = item.day;
      let hour = item.hour;
      let monthStr = month > 9 ? month : "0" + month;
      let dateStr = day > 9 ? day : "0" + day;
      let hourStr = hour > 9 ? hour : "0" + hour;
      state.userInfo.birthday = item.year + "-" + monthStr + "-" + dateStr + " " + hourStr + ":00" + ":00";
      state.userInfo.birthday2 = "";
      state.userInfo.qiPanFangShi = "1";
    };

    const paipan = function () {
      if (state.currentIndex == null) {
        bus.emit("modal-show", {
          msg: `请选择年份后再进行排盘！`,
        });
      } else {
        state.userInfo.qiPanFangShi = 1;
        testPaipan(state.userInfo)
          .then(testPaipanSuccess)
          .catch((err) => {
            console.log(err);
          });
      }
    };

    const testPaipanSuccess = function (result) {
      if (result.status == 200 && result.data.status == 1) {
        SessionStorage.get("nie").then((res) => {
          if (res) {
            result.data.data.userName = state.userInfo.userName;
            result.data.data.sex = state.userInfo.sex;
            SessionStorage.set("query-info", state.userInfo);
            localStorage.setItem("birthday", result.data.data.birthday);
            console.log(result.data.data.birthday);
            localStorage.setItem("query-info", JSON.stringify(state.userInfo));
            SessionStorage.set("paipan-h5-date", result.data.data);
            let url = router.resolve({
              path: "/bazi/baziResult",
            });
            window.open(url.href);
            state.isShow = false;
          } else if (res == null) {
            result.data.data.userName = state.userInfo.userName;
            result.data.data.sex = state.userInfo.sex;
            SessionStorage.set("query-info", state.userInfo);
            localStorage.setItem("birthday", result.data.data.birthday);
            console.log(result.data.data.birthday);
            localStorage.setItem("query-info", JSON.stringify(state.userInfo));
            SessionStorage.set("paipan-h5-date", result.data.data);
            let url = router.resolve({
              path: "/bazipaipan/result",
            });
            window.open(url.href);
            state.isShow = false;
          }
        });
      }
    };

    const initEvents = function () {
      bus.on("my-fantuiMTK", modalShow);
    };
    initEvents();
    const quxiao = () => {
      state.isShow = false;
    };
    return {
      state: state,
      close: close,
      changeYear: changeYear,
      paipan: paipan,
      quxiao: quxiao,
    };
  },
});
</script>

<style scoped>
.my-pay-modal {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(33, 33, 33, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #252525;
  font-weight: 400;
}

.fantuimodel {
  width: 484px;
  min-height: 324px;
  height: auto;
  box-sizing: border-box;
  border: 1px solid #eaeaea;
  padding: 0 32px;
  background-color: #fff;
}

.fantuiheader {
  width: 100%;
  height: 95px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: center;
  position: relative;
}

.closebtn {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.fantuibody {
  width: 100%;
  height: calc(100% - 95px);
  padding-top: 29px;
  padding-bottom: 40px;
}

.yearDivlist {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.yearDiv {
  width: 96px;
  height: 39px;
  border: 1px solid #eaeaea;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  cursor: pointer;
}

.yearDiv:hover {
  border: 1px solid #e64b39;
  color: #e64b39;
}

.yearDiv:nth-child(1),
.yearDiv:nth-child(2),
.yearDiv:nth-child(3),
.yearDiv:nth-child(4) {
  margin-top: 0;
}

.activell {
  border: 1px solid #e64b39;
  color: #e64b39;
}

.fantuibtn {
  width: 140px;
  height: 42px;
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #e64b39;
  margin: 29px auto 0;
  cursor: pointer;
}

.fantuibtn:hover,
.fantuibtn:active {
  background-color: #c53423;
}
</style>
