<template>
	<div class="my-bazi">
		<div class="bazi_content">
			<div class="bazi_left">姓名</div>
			<div class="bazi_right">
				<input type="text" class="input" v-model="state.userInfo.userName" />
			</div>
		</div>
		<div class="bazi_content top20">
			<div class="bazi_left">性别</div>
			<div class="bazi_right" style="line-height: 42px">
				<input class="form-check-input" type="radio" :checked="state.userInfo.sex == 1" @change="sexChange(1)"
					style="width: 20px; height: 20px; margin-top: 11px; margin-right: 15px" />男
				<input class="form-check-input" type="radio" :checked="state.userInfo.sex == 2" @change="sexChange(2)"
					style="width: 20px; height: 20px; margin-left: 35px; margin-top: 11px; margin-right: 15px" />女
			</div>
		</div>
		<div class="bazi_content top20">
			<div class="bazi_left">出生地</div>
			<div class="bazi_right" style="line-height: 42px; font-weight: 200; display: flex">
				<div class="jiantou" style="width: 130px">
					<img :src="require(`../../public/images/${state.tubiao[0]}.png`)" class="qiehuan" />
					<select v-model="state.provinceId" style="padding-left: 10px; width: 130px" class="sr huan"
						@change="provinceId(0)" @mouseenter="aaa(0)" @mouseleave="bbb(0)" @click="ccc(0)"
						@blur="ddd(0)">
						<option value="" style="display: none">请选择</option>
						<template v-for="(item, index) in state.province" :key="index">
							<option :value="item.id">{{ item.name }}</option>
						</template>
					</select>
				</div>
				<div class="jiantou" style="margin-left: 10px; width: 130px">
					<img :src="require(`../../public/images/${state.tubiao[1]}.png`)" class="qiehuan" />
					<select style="width: 130px" class="sr huan" @mouseenter="aaa(1)" @mouseleave="bbb(1)"
						@change="provinceId1(1)" @click="ccc(1)" @blur="ddd(1)">
						<template v-for="(item, index) in state.city" :key="index">
							<option :value="item.name">{{ item.name }}</option>
						</template>
					</select>
				</div>
			</div>
		</div>
		<div class="bazi_content top20">
			<div class="bazi_left">生日</div>
			<div class="bazi_right" style="display: flex">
				<div class="jiantou">
					<img :src="require(`../../public/images/${state.tubiao[2]}.png`)" class="qiehuan" />
					<select class="sr huan" v-model="state.qpfs" @change="chengeqipan(2)" @mouseenter="aaa(2)"
						@mouseleave="bbb(2)" @click="ccc(2)" @blur="ddd(2)">
						<template v-for="(item, index) in state.qipanArr" :key="index">
							<option :value="index + 1">{{ item }}</option>
						</template>
					</select>
				</div>
				<!-- 阳历情况 -->
				<template v-if="state.qpfs == 1">
					<div class="jiantou" style="margin-left: 9px">
						<img :src="require(`../../public/images/${state.tubiao[3]}.png`)" class="qiehuan" />
						<select class="sr huan" v-model="state.gongli.ful" @change="yearChange(3)" @mouseenter="aaa(3)"
							@mouseleave="bbb(3)" @click="ccc(3)" @blur="ddd(3)">
							<template v-for="(item, index) in state.years" :key="index">
								<option :value="item">{{ item }}年</option>
							</template>
						</select>
					</div>
					<div class="jiantou" style="margin-left: 9px">
						<img :src="require(`../../public/images/${state.tubiao[4]}.png`)" class="qiehuan" />
						<select class="sr huan" v-model="state.gongli.mon" @change="monthChange(4)" @mouseenter="aaa(4)"
							@mouseleave="bbb(4)" @click="ccc(4)" @blur="ddd(4)">
							<template v-for="(item, index) in state.months" :key="index">
								<option :value="item">{{ item }}月</option>
							</template>
						</select>
					</div>
					<div class="jiantou" style="margin-left: 9px">
						<img :src="require(`../../public/images/${state.tubiao[5]}.png`)" class="qiehuan" />
						<select class="sr huan" v-model="state.gongli.date" @change="dayChange(5)" @mouseenter="aaa(5)"
							@mouseleave="bbb(5)" @click="ccc(5)" @blur="ddd(5)">
							<template v-for="(item, index) in state.dates" :key="index">
								<option :value="item">{{ item }}日</option>
							</template>
						</select>
					</div>
					<div class="jiantou" style="margin-left: 9px">
						<img :src="require(`../../public/images/${state.tubiao[6]}.png`)" class="qiehuan" />
						<select class="sr huan" v-model="state.gongli.hours" @change="hourChange(6)"
							@mouseenter="aaa(6)" @mouseleave="bbb(6)" @click="ccc(6)" @blur="ddd(6)">
							<template v-for="(item, index) in state.hours" :key="index">
								<option :value="item">{{ item }}时</option>
							</template>
						</select>
					</div>
					<div class="jiantou" style="margin-left: 9px">
						<img :src="require(`../../public/images/${state.tubiao[7]}.png`)" class="qiehuan" />
						<select class="sr huan" v-model="state.gongli.min" @change="minuteChange(7)"
							@mouseenter="aaa(7)" @mouseleave="bbb(7)" @click="ccc(7)" @blur="ddd(7)">
							<template v-for="(item, index) in state.minutes" :key="index">
								<option :value="item">{{ item }}分</option>
							</template>
						</select>
					</div>
				</template>
				<!-- 阴历情况 -->
				<template v-if="state.qpfs == 2">
					<div class="jiantou" style="margin-left: 9px">
						<img :src="require(`../../public/images/${state.tubiao[3]}.png`)" class="qiehuan" />
						<select class="sr huan" v-model="state.nongli.ful" @change="yearChange(3)" @mouseenter="aaa(3)"
							@mouseleave="bbb(3)" @click="ccc(3)" @blur="ddd(3)">
							<template v-for="(item, index) in state.years" :key="index">
								<option :value="item">{{ item }}年</option>
							</template>
						</select>
					</div>
					<div class="jiantou" style="margin-left: 9px">
						<img :src="require(`../../public/images/${state.tubiao[4]}.png`)" class="qiehuan" />
						<select class="sr huan" v-model="state.monthobj" @change="monthChange(4)" @mouseenter="aaa(4)"
							@mouseleave="bbb(4)" @click="ccc(4)" @blur="ddd(4)">
							<template v-for="(item, index) in state.lunarMonthes" :key="index">
								<option :value="item">{{ item.name }}月</option>
							</template>
						</select>
					</div>
					<div class="jiantou" style="margin-left: 9px">
						<img :src="require(`../../public/images/${state.tubiao[5]}.png`)" class="qiehuan" />
						<select class="sr huan" v-model="state.nongli.date" @change="dayChange(5)" @mouseenter="aaa(5)"
							@mouseleave="bbb(5)" @click="ccc(5)" @blur="ddd(5)">
							<template v-for="(item, index) in state.lunarDates" :key="index">
								<option :value="item.code">{{ item.name }}</option>
							</template>
						</select>
					</div>
					<div class="jiantou" style="margin-left: 9px">
						<img :src="require(`../../public/images/${state.tubiao[6]}.png`)" class="qiehuan" />
						<select class="sr huan" v-model="state.nongli.hours" @change="hourChange(6)"
							@mouseenter="aaa(6)" @mouseleave="bbb(6)" @click="ccc(6)" @blur="ddd(6)">
							<template v-for="(item, index) in state.lunarHours" :key="index">
								<option :value="item.code">{{ item.name }}</option>
							</template>
						</select>
					</div>
					<div class="jiantou" style="margin-left: 9px">
						<img :src="require(`../../public/images/${state.tubiao[7]}.png`)" class="qiehuan" />
						<select class="sr huan" v-model="state.nongli.min" @change="minuteChange(7)"
							@mouseenter="aaa(7)" @mouseleave="bbb(7)" @click="ccc(7)" @blur="ddd(7)">
							<template v-for="(item, index) in state.minutes" :key="index">
								<option :value="item">{{ item }}分</option>
							</template>
						</select>
					</div>
				</template>
				<template v-if="state.qpfs == 3">
					<div class="jiantou" style="margin-left: 9px">
						<img :src="require(`../../public/images/${state.tubiao[3]}.png`)" class="qiehuan" />
						<select class="sr ml10 huan" v-model="fantui.ygz" @change="getmgz(3)" @mouseenter="aaa(3)"
							@mouseleave="bbb(3)" @click="ccc(3)" @blur="ddd(3)">
							<template v-for="(item, index) in state.ygzArr" :key="index">
								<option selected :value="item">{{ item }}</option>
							</template>
						</select>
					</div>
					<div class="jiantou" style="margin-left: 9px">
						<img :src="require(`../../public/images/${state.tubiao[4]}.png`)" class="qiehuan" />
						<select class="sr ml10 huan" v-model="fantui.mgz" @change="getmigz(4)" @mouseenter="aaa(4)"
							@mouseleave="bbb(4)" @click="ccc(4)" @blur="ddd(4)">
							<template v-for="(item, index) in state.mgzArr" :key="index">
								<option selected :value="item">{{ item }}</option>
							</template>
						</select>
					</div>
					<div class="jiantou" style="margin-left: 9px">
						<img :src="require(`../../public/images/${state.tubiao[5]}.png`)" class="qiehuan" />
						<select class="sr ml10 huan" v-model="fantui.dgz" @change="gethgz(5)" @mouseenter="aaa(5)"
							@mouseleave="bbb(5)" @click="ccc(5)" @blur="ddd(5)">
							<template v-for="(item, index) in state.dgzArr" :key="index">
								<option selected :value="item">{{ item }}</option>
							</template>
						</select>
					</div>
					<div class="jiantou" style="margin-left: 9px">
						<img :src="require(`../../public/images/${state.tubiao[6]}.png`)" class="qiehuan" />
						<select class="sr ml10 huan" v-model="fantui.hgz" @change="gethogz(6)" @mouseenter="aaa(6)"
							@mouseleave="bbb(6)" @click="ccc(6)" @blur="ddd(6)">
							<template v-for="(item, index) in state.hgzArr" :key="index">
								<option selected :value="item">{{ item }}</option>
							</template>
						</select>
					</div>
				</template>
			</div>
		</div>
		<div class="bazi_content top20">
			<div class="form-group" style="color: #252525; font-weight: 200"><input class="form-check-input"
					type="checkbox" style="margin-right: 4px; width: 20px; height: 20px" v-model="state.dunWeiFlag"
					@change="flagChange" /> 夜子时日干向前遁一位</div>
		</div>
		<div class="paipanbtn" @click="fantuibtn" v-if="state.qpfs == 3">开始排盘</div>
		<div class="paipanbtn" @click="startTest" v-else>开始排盘</div>
	</div>
</template>

<script>
	import {
		defineComponent,
		reactive
	} from "vue";
	import {
		years,
		months,
		dates,
		hours,
		minutes,
		lunarMonthes,
		lunarDates,
		lunarHours,
		ygzArr,
		dgzArr,
		province
	} from "../data/data.js";
	import {
		testPaipan,
		dateConvert,
		getyz,
		gethz,
		fanTui,
		getCityByProvince
	} from "../api/paipan.js";
	import bus from "../common/scripts/bus.js";
	import SessionStorage from "../common/scripts/session-storage.js";
	import {
		useRouter
	} from "vue-router";
	const {
		SolarUtil,
		LunarYear
	} = require("lunar-javascript");
	export default defineComponent({
		name: "my-bazi",
		setup() {
			const router = useRouter();
			const userInfo = {
				userName: "",
				birthday: "",
				birthdayStr: "",
				birthday2: "",
				birthday2Str: "",
				sex: 1,
				dunWeiFlag: 0,
				dunWeiFlagVal: 0,
				qiPanFangShi: 1,
				leapFlag: 0,
				agree: false,
			};
			const fantui = reactive({
				ygz: "年柱",
				mgz: "月柱",
				dgz: "日柱",
				hgz: "时柱",
			});
			const state = reactive({
				userInfo: userInfo,
				years: years,
				months: months,
				dates: dates,
				hours: hours,
				minutes: minutes,
				ygzArr: ygzArr,
				mgzArr: ["月柱"],
				dgzArr: dgzArr,
				hgzArr: ["时柱"],
				dunWeiFlag: false,
				lunarMonthes: lunarMonthes,
				lunarDates: lunarDates,
				lunarHours: lunarHours,
				qipanArr: ["阳历", "阴历", "反推"],
				province: province,
				qpfs: 1, //1阳历 2阴历 3反推
				dateOptions: {
					year: "",
					lYear: "",
					month: "",
					lMonth: "",
					day: "",
					lDay: "",
					hour: "",
					lHour: "",
					minute: "",
				},
				gongli: {
					ful: "",
					mon: "",
					date: "",
					hours: "",
					min: "",
				},
				nongli: {
					ful: "",
					mon: "",
					date: "",
					hours: "",
					ry: "",
				},
				monthobj: {},
				provinceId: "",
				city: [],
				tubiao: ["xia", "xia", "xia", "xia", "xia", "xia", "xia", "xia", "xia", "xia", "xia"],
			});

			//初始化数据
			const initDate = function() {
				let a = new Date();
				let ful = a.getFullYear();
				let mon = a.getMonth() + 1;
				let date = a.getDate();
				let hours = a.getHours();
				let min = a.getMinutes();
				state.gongli.ful = ful;
				state.gongli.mon = mon;
				state.gongli.date = date;
				state.gongli.hours = hours;
				state.gongli.min = min;
				state.userInfo.birthday = gongli();
				state.userInfo.qiPanFangShi = "1";
			};
			//棋盘方式
			const chengeqipan = function(val) {
				setTimeout(() => {
					let b = document.querySelectorAll('.qiehuan')
					for (let i = 0; i < b.length; i++) {
						b[i].classList.remove('zhuan')
					}
					b[val].classList.add('zhuan1')
				}, 10)
				if (state.qpfs == 1) {
					state.userInfo.qiPanFangShi = "2";
					state.userInfo.birthday2 = nongli();
				} else if (state.qpfs == 2) {
					state.userInfo.qiPanFangShi = "1";
					state.userInfo.birthday = gongli();
				} else if (state.qpfs == 3) {
					state.userInfo.qiPanFangShi = "1";
					state.userInfo.birthday = gongli();
				}
				dateConvert(state.userInfo).then(dateConvertSuccess).catch(dateConvertError);
			};
			//性别
			const sexChange = function(sex) {
				state.userInfo.sex = sex;
			};
			//阴阳历转换接口
			const dateConvertSuccess = function(result) {
				if (result.status == 200 && result.data.status == 1) {
					let date = result.data.data;
					if (state.userInfo.qiPanFangShi == 1) {
						state.userInfo.qiPanFangShi = 2;
						state.nongli.ful = date.lunarYear;
						state.nongli.mon = date.lunarMonth;
						state.nongli.date = date.lunarDay;
						state.nongli.hours = date.lunarHour;
						state.nongli.ry = date.leepFlag;
						state.gongli.ful = date.solarYear;
						state.gongli.mon = date.solarMonth;
						state.gongli.date = date.solarDay;
						state.gongli.hours = date.solarHour;
						state.gongli.min = new Date().getMinutes();
						state.nongli.min = new Date().getMinutes();
						// console.log('1 农', state.nongli);
						// console.log('1 公', state.gongli);
						nongli();
						gongli();
						huixian(state.nongli.mon);
					} else if (state.userInfo.qiPanFangShi == 2) {
						state.userInfo.qiPanFangShi = 1;
						state.gongli.ful = date.solarYear;
						state.gongli.mon = date.solarMonth;
						state.gongli.date = date.solarDay;
						state.gongli.hours = date.solarHour;
						state.gongli.min = new Date().getMinutes();
						state.nongli.ful = date.lunarYear;
						state.nongli.mon = date.lunarMonth;
						state.nongli.date = date.lunarDay;
						state.nongli.hours = date.lunarHour;
						state.nongli.min = new Date().getMinutes();
						// console.log('2 农', state.nongli);
						// console.log('2 公', state.gongli);
						nongli();
						gongli();
						huixian(state.nongli.mon);
					}
					getleap(state.nongli.ful);
				}
			};

			//数据回显
			const huixian = function(a) {
				state.lunarMonthes.forEach((item) => {
					if (item.code == a) {
						console.log(item);
						state.monthobj = item;
					}
				});
			};

			const dateConvertError = function() {
				// state.toastMsg = '服务器异常,请与工作人员联系！'
				// state.toastShow = true
			};
			//农历公历转换
			//公历
			const gongli = function() {
				let fulStr = state.gongli.ful > 9 ? state.gongli.ful : "0" + state.gongli.ful;
				let monStr = state.gongli.mon > 9 ? state.gongli.mon : "0" + state.gongli.mon;
				let dateStr = state.gongli.date > 9 ? state.gongli.date : "0" + state.gongli.date;
				let hoursStr = state.gongli.hours > 9 ? state.gongli.hours : "0" + state.gongli.hours;
				let minStr = state.gongli.min > 9 ? state.gongli.min : "0" + state.gongli.min;
				let str = fulStr + "-" + monStr + "-" + dateStr + " " + hoursStr + ":" + minStr + ":" + "00";
				// console.log('公历', str);
				state.userInfo.birthday = str;
				return str;
			};
			//农历
			const nongli = function() {
				let fulStr = state.nongli.ful > 9 ? state.nongli.ful : "0" + state.nongli.ful;
				let monStr = state.nongli.mon > 9 ? state.nongli.mon : "0" + state.nongli.mon;
				let dateStr = state.nongli.date > 9 ? state.nongli.date : "0" + state.nongli.date;
				let hoursStr = state.nongli.hours > 9 ? state.nongli.hours : "0" + state.nongli.hours;
				let minStr = state.gongli.min > 9 ? state.gongli.min : "0" + state.gongli.min;
				let str = fulStr + "-" + monStr + "-" + dateStr + " " + hoursStr + ":" + minStr + ":" + "00";
				// console.log('农历', str);
				state.userInfo.birthday2 = str;
				return str;
			};
			//日期转换
			const yearChange = function(val) {
				setTimeout(() => {
					let b = document.querySelectorAll('.qiehuan')
					for (let i = 0; i < b.length; i++) {
						b[i].classList.remove('zhuan')
					}
					b[val].classList.add('zhuan1')
				}, 10);
				if (state.userInfo.qiPanFangShi == 1) {
					let a = SolarUtil.getDaysOfMonth(state.gongli.ful, state.gongli.mon);
					state.dates = [];
					for (let i = 1; i <= a; i++) {
						state.dates.push(i);
					}
					if (state.gongli.date > Number(state.dates[state.dates.length - 1])) {
						state.gongli.date = state.dates[state.dates.length - 1];
					}
					state.userInfo.birthday = gongli();
				} else {
					getleap(state.nongli.ful);
					console.log(state.nongli.ful);
					state.userInfo.birthday2 = nongli();
				}
			};

			const monthChange = function(val) {
				setTimeout(() => {
					let b = document.querySelectorAll('.qiehuan')
					for (let i = 0; i < b.length; i++) {
						b[i].classList.remove('zhuan')
					}
					b[val].classList.add('zhuan1')
				}, 10);
				if (state.userInfo.qiPanFangShi == 1) {
					let a = SolarUtil.getDaysOfMonth(state.gongli.ful, state.gongli.mon);
					state.dates = [];
					for (let i = 1; i <= a; i++) {
						state.dates.push(i);
					}
					console.log(state.gongli.date);
					if (state.gongli.date > Number(state.dates[state.dates.length - 1])) {
						state.gongli.date = state.dates[state.dates.length - 1];
					}
					state.userInfo.birthday = gongli();
				} else {
					console.log('state.monthobj',state.monthobj);
					state.nongli.mon = state.monthobj.code;
					runyue(state.monthobj.name);
					state.userInfo.birthday2 = nongli();
				}
			};

			const runyue = function(str) {
				console.log(str);
				if (str.indexOf("闰") != -1) {
					// 闰月
					state.userInfo.leapFlag = "1";
					console.log(state.userInfo.leapFlag);
				} else {
					state.userInfo.leapFlag = "0";
					return;
				}
			};
			const dayChange = function(val) {
				setTimeout(() => {
					let b = document.querySelectorAll('.qiehuan')
					for (let i = 0; i < b.length; i++) {
						b[i].classList.remove('zhuan')
					}
					b[val].classList.add('zhuan1')
				}, 10);
				if (state.userInfo.qiPanFangShi == 1) {
					state.userInfo.birthday = gongli();
				} else {
					state.userInfo.birthday2 = nongli();
				}
			};

			const hourChange = function(val) {
				setTimeout(() => {
					let b = document.querySelectorAll('.qiehuan')
					for (let i = 0; i < b.length; i++) {
						b[i].classList.remove('zhuan')
					}
					b[val].classList.add('zhuan1')
				}, 10);
				if (state.userInfo.qiPanFangShi == 1) {
					state.userInfo.birthday = gongli();
				} else {
					state.userInfo.birthday2 = nongli();
				}
			};

			const minuteChange = function(val) {
				setTimeout(() => {
					let b = document.querySelectorAll('.qiehuan')
					for (let i = 0; i < b.length; i++) {
						b[i].classList.remove('zhuan')
					}
					b[val].classList.add('zhuan1')
				}, 10);
				if (state.userInfo.qiPanFangShi == 1) {
					state.userInfo.birthday = gongli();
				} else {
					state.userInfo.birthday2 = nongli();
				}
			};
			//获取闰月
			const getleap = function(year) {
				console.log('year',year);
				let a = LunarYear.fromYear(year);
				console.log(a.getLeapMonth());
				if (a.getLeapMonth() != undefined && a.getLeapMonth() != 0) {
					// 有闰月
					let b = a.getLeapMonth();
					state.lunarMonthes = [{
							code: 1,
							name: "正",
						},
						{
							code: 2,
							name: "二",
						},
						{
							code: 3,
							name: "三",
						},
						{
							code: 4,
							name: "四",
						},
						{
							code: 5,
							name: "五",
						},
						{
							code: 6,
							name: "六",
						},
						{
							code: 7,
							name: "七",
						},
						{
							code: 8,
							name: "八",
						},
						{
							code: 9,
							name: "九",
						},
						{
							code: 10,
							name: "十",
						},
						{
							code: 11,
							name: "冬",
						},
						{
							code: 12,
							name: "腊",
						},
					];
					state.lunarMonthes.splice(b, 0, {
						code: b,
						name: `闰${numToCn(b)}`,
					});
				} else {
					state.lunarMonthes = [{
							code: 1,
							name: "正",
						},
						{
							code: 2,
							name: "二",
						},
						{
							code: 3,
							name: "三",
						},
						{
							code: 4,
							name: "四",
						},
						{
							code: 5,
							name: "五",
						},
						{
							code: 6,
							name: "六",
						},
						{
							code: 7,
							name: "七",
						},
						{
							code: 8,
							name: "八",
						},
						{
							code: 9,
							name: "九",
						},
						{
							code: 10,
							name: "十",
						},
						{
							code: 11,
							name: "冬",
						},
						{
							code: 12,
							name: "腊",
						},
					];
					console.log(state.newlunMon);
					return;
				}
			};
			//数字从小写转为大写函数
			const numToCn = function(num) {
				// 定义数字和单位对应的中文表达形式
				const numCn = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
				const unitCn = ["", "十", "百", "千", "万", "亿"];
				//将数字转换为字符串
				let str = num.toString();
				//用于记录结果的字符串
				let result = "";
				//用于记录当前数字位于整个数字的哪一位
				let digitIndex = str.length - 1;
				//用于记录当前数字在所处位数中的位置，0表示最高位，1表示次高位，以此类推
				let digitPos = 0;
				//检查数字是否是负数
				const isNegative = str[0] === "-";
				if (isNegative) {
					str = str.slice(1);
					digitIndex--;
				}
				//对每一位数字进行处理，从高位到低位
				for (let i = 0; i < str.length; i++) {
					//获取当前位的数字
					const digit = parseInt(str[i]);
					//计算当前数字所在的单位
					const unit = (digitIndex - i) % 4;
					//如果当前数字为0，则需要特殊处理
					if (digit === 0) {
						//如果当前数字所在单位为万或亿，则加上对应的单位
						if (unit === 0 && (digitIndex - i) / 4 >= 1) {
							result += unitCn[(digitIndex - i) / 4];
							digitPos = 0;
						} else {
							if (digitPos !== 0) {
								result += numCn[digit];
							}
							digitPos++;
						}
					} else {
						//对非0数字的处理，加上数字本身和对应的单位
						result += numCn[digit] + unitCn[unit];
						digitPos = 0;
					}
				}
				//如果数字为0，则结果为“零”
				if (result === "") {
					result = numCn[0];
				}
				//如果是负数，则加上“负”
				if (isNegative) {
					result = "负" + result;
				}
				return result;
			};

			//反推

			const fantuibtn = function() {
				if (!state.userInfo.userName) {
					bus.emit("modal-show", {
						title: "信息提示",
						msg: "姓名不能为空！",
					});
					return;
				}
				fanTui(fantui)
					.then(fanTuiSuccess)
					.catch((err) => {
						console.log(err);
					});
			};

			const fanTuiSuccess = function(result) {
				if (result.status == 200 && result.data.status == 1) {
					userInfo.fantuibirthdatArr = result.data.data;
					console.log(result.data.data);
					if (result.data.data.length != 0) {
						bus.emit("my-fantuiMTK", {
							title: "年份选择",
							option: userInfo,
						});
					}
				}
			};

			const getmgz = function(val) {
				setTimeout(() => {
					let b = document.querySelectorAll('.qiehuan')
					for (let i = 0; i < b.length; i++) {
						b[i].classList.remove('zhuan')
					}
					b[val].classList.add('zhuan1')
				}, 10);
				getyz({
						key: fantui.ygz,
					})
					.then(getyzSuccess)
					.catch((err) => {
						console.log(err);
					});
			};

			const getyzSuccess = function(result) {
				if (result.status == 200 && result.data.status == 1) {
					state.mgzArr = result.data.data;
					fantui.mgz = result.data.data[0];
				}
			};

			const gethgz = function(val) {
				setTimeout(() => {
					let b = document.querySelectorAll('.qiehuan')
					for (let i = 0; i < b.length; i++) {
						b[i].classList.remove('zhuan')
					}
					b[val].classList.add('zhuan1')
				}, 10);
				gethz({
						key: fantui.dgz,
					})
					.then(gethzSuccess)
					.catch((err) => {
						console.log(err);
					});
			};

			const gethzSuccess = function(result) {
				if (result.status == 200 && result.data.status == 1) {
					state.hgzArr = result.data.data;
					fantui.hgz = result.data.data[0];
				}
			};

			const flagChange = function() {
				if (state.dunWeiFlag) {
					state.userInfo.dunWeiFlag = 1;
				} else {
					state.userInfo.dunWeiFlag = 0;
				}
			};

			const startTest = function() {
				console.log(state.userInfo);
				if (!state.userInfo.userName) {
					bus.emit("modal-show", {
						title: "信息提示",
						msg: "姓名不能为空！",
					});
					return;
				}

				testPaipan(state.userInfo).then(testPaipanSuccess).catch(testPaipanError);
			};
			const testPaipanSuccess = function(result) {
				if (result.status == 200 && result.data.status == 1) {
					SessionStorage.get('nie').then(res => {
						console.log(res);
						if (res) {
							result.data.data.userName = state.userInfo.userName;
							result.data.data.sex = state.userInfo.sex;
							SessionStorage.set("query-info", state.userInfo);
							localStorage.setItem("query-info", JSON.stringify(state.userInfo));
							SessionStorage.set("paipan-h5-date", result.data.data);
							let url = router.resolve({
								path: "/bazi/baziResult",
							});
							window.open(url.href);
						} else if (res == null) {
							result.data.data.userName = state.userInfo.userName;
							result.data.data.sex = state.userInfo.sex;
							SessionStorage.set("query-info", state.userInfo);
							localStorage.setItem("query-info", JSON.stringify(state.userInfo));
							SessionStorage.set("paipan-h5-date", result.data.data);
							let url = router.resolve({
								path: "/bazipaipan/result",
							});
							window.open(url.href);
						}
					})
				}
			};
			const testPaipanError = function(err) {
				console.log(err);
			};
			const provinceId = (val) => {
				setTimeout(() => {
					let b = document.querySelectorAll('.qiehuan')
					for (let i = 0; i < b.length; i++) {
						b[i].classList.remove('zhuan')
					}
					b[val].classList.add('zhuan1')
				}, 10)
				getCityByProvince(state.provinceId)
					.then((res) => {
						if (res.status == 200 && res.data.status == 1) {
							state.city = res.data.data;
						} else {
							console.log(res.data.mag);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}; // 添加红色边框
			const aaa = (val) => {
				let a = document.querySelectorAll(".sr");
				a[val].classList.add("se");
			};
			// 移除红色边框
			const bbb = (val) => {
				let a = document.querySelectorAll(".sr");
				a[val].classList.remove("se");
			};
			// 箭头改为上 点击移除边框
			const ccc = (val) => {
				let a = document.querySelectorAll(".sr");
				let b = document.querySelectorAll('.qiehuan')
				for (let i = 0; i < b.length; i++) {
					b[i].classList.remove('zhuan1')
				}
				b[val].classList.add('zhuan')
				a[val].classList.remove("se");
			};
			// 箭头改为下
			const ddd = (val) => {
				// state.tubiao[val] = "xia";
				let b = document.querySelectorAll('.qiehuan')
				for (let i = 0; i < b.length; i++) {
					b[i].classList.remove('zhuan')
				}
				b[val].classList.add('zhuan1')
			};
			const getmigz = (val) => {
				setTimeout(() => {
					let b = document.querySelectorAll('.qiehuan')
					for (let i = 0; i < b.length; i++) {
						b[i].classList.remove('zhuan')
					}
					b[val].classList.add('zhuan1')
				}, 10);
			};
			const gethogz = (val) => {
				setTimeout(() => {
					let b = document.querySelectorAll('.qiehuan')
					for (let i = 0; i < b.length; i++) {
						b[i].classList.remove('zhuan')
					}
					b[val].classList.add('zhuan1')
				}, 10);
			};
			const provinceId1 = (val) => {
				setTimeout(() => {
					let b = document.querySelectorAll('.qiehuan')
					for (let i = 0; i < b.length; i++) {
						b[i].classList.remove('zhuan')
					}
					b[val].classList.add('zhuan1')
				}, 10)
			};
			initDate();
			return {
				state: state,
				chengeqipan: chengeqipan,
				gongli: gongli,
				nongli: nongli,
				yearChange: yearChange,
				monthChange: monthChange,
				dayChange: dayChange,
				hourChange: hourChange,
				minuteChange: minuteChange,
				fantui: fantui,
				getmgz: getmgz,
				gethgz: gethgz,
				flagChange: flagChange,
				startTest: startTest,
				sexChange: sexChange,
				fantuibtn: fantuibtn,
				provinceId: provinceId,
				getmigz: getmigz,
				gethogz: gethogz,
				provinceId1: provinceId1,
				aaa: aaa,
				bbb: bbb,
				ccc: ccc,
				ddd: ddd,
			};
		},
	});
</script>

<style>
	.my-bazi {
		width: 100%;
		height: 100%;
		padding: 40px 77px;
	}

	.bazi_content {
		width: 100%;
		height: 42px;
		display: flex;
	}

	.bazi_left {
		width: 91px;
		height: 42px;
		line-height: 42px;
		text-align: left;
		font-size: 16px;
		color: #0a0a0a;
	}

	.input {
		line-height: 42px;
		width: 280px;
		height: 42px;
		border: 1px solid #eaeaea;
		padding-left: 10px;
		font-weight: 200;
	}

	.input:focus-visible {
		border: 1px solid #999;
		outline: none;
		/* outline: none;
		border: 1px solid #cdcdcd;
		color: #252525 */
	}

	select:focus {
		outline: none;
		border: 1px solid #cdcdcd;
		color: #252525;
	}

	select:hover {
		border: 1px solid #e64b39;
	}

	.top20 {
		margin-top: 20px;
	}

	/* 	.form-check-input:checked[type=radio] {
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='red'/%3e%3c/svg%3e");
		background-size: 130%;
	} */

	.form-check-input:checked {
		background-color: #fff;
		border-color: #e64b39 !important;
	}

	.form-check-input:focus {
		box-shadow: 0 0 0 0;
	}

	select {
		font-weight: 200;
		width: 130px;
		height: 42px;
		border: 1px solid #eaeaea;
	}

	.ml10 {
		margin-left: 10px;
	}

	.bazi_right .birthinput {
		width: 104px;
		height: 42px;
		border: 1px solid #eaeaea;
		padding-left: 10px;
		font-weight: 200;
	}

	.paipanbtn {
		width: 162px;
		height: 42px;
		background-color: #e64b39;
		color: #fff;
		text-align: center;
		line-height: 42px;
		margin: 0 auto;
		margin-top: 23px;
		cursor: pointer;
	}

	.paipanbtn:active,
	.paipanbtn:hover {
		background-color: #c53423 !important;
	}

	select {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
	}

	.my-bazi select::after {
		content: "";
		position: absolute;
		top: 50%;
		right: 5px;
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 5px solid #000;
		transform: translateY(-50%);
	}

	input:focus,
	select:focus,
	textarea:focus {
		outline: none;
		border: 1px solid #cdcdcd;
		color: #252525;
	}

	.my-bazi .form-check-input[type="checkbox"]:checked {
		background-color: #e03229;
		border-color: #e03229;
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
	}

	.my-bazi .jiantou {
		position: relative;
		width: 103px;
	}

	.my-bazi .qiehuan {
		position: absolute;
		right: 11px;
		top: 17px;
		z-index: 99;
	}

	.my-bazi .bazi_content .sr {
		width: 103px;
		padding-left: 10px;
	}

	.my-bazi .bazi_content .sr:focus {
		border: 1px solid #999999;
	}

	.my-bazi .se {
		border: 1px solid #999 !important;
		/* color: #e64b39; */
	}

	.my-bazi .goumai .xinxitianxie .beizhuxinxi .beizhu:focus {
		border: 1px solid #999;
	}

	.my-bazi .zhifu {
		width: 161px;
		height: 42px;
		color: #fff;
		background-color: #e64b39;
		line-height: 42px;
		margin-top: 22px;
		cursor: pointer;
	}

	.my-bazi .zhifu:active {
		background-color: #c53423;
	}

	.my-bazi .zhuan {
		animation: zhuan 0.5s 1;
		animation-fill-mode: forwards;
	}

	@keyframes zhuan {
		0% {
			transform: rotate(0deg)
		}

		100% {
			transform: rotate(180deg)
		}
	}

	.my-bazi .zhuan1 {
		animation: zhuan1 0.5s 1;
		animation-fill-mode: forwards;
	}

	@keyframes zhuan1 {
		0% {
			transform: rotate(180deg)
		}

		100% {
			transform: rotate(0deg)
		}
	}
</style>