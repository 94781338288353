<template>
  <div class="status-bar">
    <div style="position: relative; left: 75px">
      <img src="../../public/images/log.png" />
    </div>
    <div style="width: 1200px; display: flex; justify-content: center; margin-left: 80px">
      <div class="daohang" @click="tiaozhuang1('/')">网站首页</div>
      <div class="daohang" @click="pibu">命理批卜</div>
      <div class="daohang" @click="gomr">命理档案</div>
      <div class="daohang">
        排盘工具
        <div class="paipan">
          <div class="paipanneirong" @click="qu('/bazipaipan/info')">八字排盘</div>
          <div class="paipanneirong" @click="qu('/crape/info')">紫微斗数</div>
          <div class="paipanneirong" @click="qu('/liuyao/info')">六爻排盘</div>
          <div class="paipanneirong" @click="qu('/liuren/info')">大六壬排盘</div>
          <div class="paipanneirong" @click="qu('/meihua/info')">梅花易数</div>
          <div class="paipanneirong" @click="qu('/qimendunjia/info')">奇门遁甲</div>
          <div class="paipanneirong" @click="qu('/chenggu/info')">袁天罡称骨</div>
          <div class="paipanneirong" @click="qu('/name/info')">姓名配对</div>
          <div class="paipanneirong" @click="qu('/cezi/info')">在线测字</div>
          <div class="paipanneirong" @click="qu('/liudao/liudaoInfo')">前世今生</div>
          <div class="paipanneirong" @click="qu('/taohuareport')">八字桃花运</div>
          <div class="paipanneirong" @click="qu('/namereport')">姓名测算</div>
        </div>
      </div>
      <div class="daohang">
        命理古籍
        <div class="guji">
          <div class="gujineirong" v-for="(item, index) in state.shuji" :key="index" @click="gotushu(item.id)">{{ item.name }}</div>
        </div>
      </div>
      <div class="daohang">
        每日一签
        <div class="chouqian">
          <div class="chouqianneirong" @click="qu('/chouqian/guanyin')">观自在签</div>
          <div class="chouqianneirong" @click="qu('/chouqian/fozu')">佛祖签</div>
          <div class="chouqianneirong" @click="qu('/chouqian/yuelao')">月老签</div>
          <div class="chouqianneirong" @click="qu('/chouqian/guandi')">关帝签</div>
          <div class="chouqianneirong" @click="qu('/chouqian/huangdaxian')">黄初平签</div>
          <div class="chouqianneirong" @click="qu('/chouqian/lvzu')">吕祖签</div>
          <div class="chouqianneirong" @click="qu('/chouqian/mazu')">妈祖签</div>
          <div class="chouqianneirong" @click="qu('/chouqian/caishen')">财神签</div>
          <div class="chouqianneirong" @click="qu('/chouqian/taishanglaojun')">太上老君签</div>
          <div class="chouqianneirong" @click="qu('/chouqian/wenshupusa')">文殊菩萨签</div>
        </div>
      </div>
      <div class="daohang" @click="qu('/huangli/info')">今日黄历</div>
      <div class="daohang" @click="qu('/shipin/list')">国学讲堂</div>
      <!-- <div class="daohang">篝火商城</div> -->
    </div>
    <div class="denglu">
      <template v-if="!state.dengli">
        <button class="aniu" style="background-color: #e64b39; color: #fff; margin-right: 9px" @click="tiaozhuang">登录</button>
        <button class="aniu" style="margin-left: 9px" @click="tiaozhuang">注册</button>
      </template>
      <template v-else-if="state.dengli">
        <span class="menu" style="display: flex; align-items: center" @mouseenter="mouseenter">
          <template v-if="state.xinxi.headImg == null || state.xinxi.headImg == ''">
            <img src="../../public/images/age1.png" style="width: 28px; height: 28px" />
          </template>
          <template v-else>
            <img :src="state.xinxi.headImg" style="width: 28px; height: 28px" />
          </template>
          <span style="margin-left: 8px; color: #fff; font-size: 18px">{{ state.xinxi.nickName }}</span>
          <template v-if="state.xinxi.messageNum != 0">
            <div style="width: 24px; height: 24px; background-color: #e64b39; border-radius: 50%; margin-left: 8px; color: #fff">
              {{ state.xinxi.messageNum }}
            </div>
          </template>
          <img :src="require(`../../public/images/${state.imgsrc}.png`)" alt="" style="width: 14px; margin-left: 8px" />
        </span>
        <div class="menuitem" v-show="state.menuStatus == 1" @mouseleave="mouseleave">
          <div class="top">
            <div style="display: flex">
              <template v-if="state.xinxi.headImg == null || state.xinxi.headImg == ''">
                <img src="../../public/images/age1.png" style="width: 46px; height: 46px" />
              </template>
              <template v-else>
                <img :src="state.xinxi.headImg" style="width: 46px; height: 46px" />
              </template>
              <div style="margin-left: 15px; display: flex; flex-direction: column">
                <div>
                  <span style="margin-right: 4px">{{ state.xinxi.nickName }}</span>
                  <template v-if="state.xinxi.vipFlag == '0'">
                    <img src="../../public/images/fVip.png" />
                  </template>
                  <template v-else-if="state.xinxi.vipFlag == '1' || state.xinxi.vipFlag == '2'">
                    <img src="../../public/images/Vip.png" />
                  </template>
                </div>
                <div style="color: #999; font-size: 12px">
                  <span>账号：</span
                  ><span v-if="state.xinxi?.loginNo != null"
                    >{{ state.xinxi.loginNo[0] }}{{ state.xinxi.loginNo[1] }}{{ state.xinxi.loginNo[2] }}****{{ state.xinxi.loginNo[state.xinxi.loginNo.length - 4] }}{{ state.xinxi.loginNo[state.xinxi.loginNo.length - 3] }}{{ state.xinxi.loginNo[state.xinxi.loginNo.length - 2]
                    }}{{ state.xinxi.loginNo[state.xinxi.loginNo.length - 1] }}</span
                  >
                </div>
              </div>
            </div>
            <div style="margin-top: 20px; height: 30px; background-color: #e64b39; display: flex; align-items: center; justify-content: center">
              <img src="../../public/images/kaitongVip.png" style="margin-right: 8px" />
              <template v-if="state.shijian == 0">
                <span style="font-size: 12px; font-weight: 200; color: #fff; cursor: pointer" @click="topayVip">成为会员：查阅更多功能</span>
              </template>
              <template v-if="state.shijian == 1">
                <span style="font-size: 12px; font-weight: 200; color: #fff; cursor: pointer" @click="topayVip"
                  >有效期:{{ state.xinxi.vipOverDate[0][0] }}/{{ state.xinxi.vipOverDate[0][1] }}/{{ state.xinxi.vipOverDate[0][2] }} 查看权益 <img src="../../public/images/chakanquanyi.png" style="margin-right: 8px"
                /></span>
              </template>
              <template v-if="state.shijian == 2">
                <span style="font-size: 12px; font-weight: 200; color: #fff; cursor: pointer" @click="topayVip">已开通永久会员</span>
              </template>
            </div>
          </div>
          <div class="bottom">
            <div style="margin-top: 23px; height: 24px; display: flex; align-items: center; cursor: pointer" class="bianse" @click="xiaoxi">
              <img src="../../public/images/xiaoxi.png" />
              <spam style="margin-left: 12px">消息</spam>
              <template v-if="state.xinxi.messageNum != 0">
                <div style="width: 24px; height: 24px; background-color: #e64b39; border-radius: 50%; margin-left: 11px; color: #fff; text-align: center">
                  {{ state.xinxi.messageNum }}
                </div>
              </template>
            </div>
            <div style="margin-top: 18px; cursor: pointer" class="bianse" @click="yuer">
              <img src="../../public/images/zhanghu.png" alt="" />
              <spam style="margin-left: 12px">账户明细(余额{{ state.xinxi.accountAmount }}元)</spam>
            </div>
            <div style="margin-top: 20px; cursor: pointer" class="bianse" @click="godingdan">
              <img src="../../public/images/goumaijilu.png" alt="" />
              <spam style="margin-left: 12px">购买记录</spam>
            </div>
            <div style="margin-top: 20px; cursor: pointer" class="bianse" @click="gochongzhi">
              <img src="../../public/images/chongzhi.png" alt="" />
              <spam style="margin-left: 12px">充值</spam>
            </div>
            <div style="margin-top: 20px; cursor: pointer" class="bianse" @click="gocundang">
              <img src="../../public/images/命理存档.png" alt="" />
              <spam style="margin-left: 12px">命理存档</spam>
            </div>
            <div style="margin-top: 20px; cursor: pointer" class="bianse" @click="shezhi">
              <img src="../../public/images/grshezhi.png" alt="" />
              <spam style="margin-left: 12px">个人设置</spam>
            </div>
          </div>
          <div style="margin: auto; margin-top: 25px; width: 215px; height: 30px; border: 1px solid #e64b39; color: #e64b39; cursor: pointer" class="tuichu" @click="tuichu">退出登录</div>
        </div>
      </template>
    </div>
  </div>
  <my-denglu></my-denglu>
  <tishikuang></tishikuang>
</template>

<script>
import { defineComponent, reactive } from "vue";
import SessionStorage from "../common/scripts/session-storage.js";
import bus from "../common/scripts/bus.js";
import denglu from "./denglu.vue";
import tishikuang from "./tishikuang.vue";

import { useRouter } from "vue-router";
import { logout, openBookList, getUserInfo, getBookmark } from "../api/newNetapi.js";
import { celebrityList } from "../api/mingren.js";
import { testPaipan } from "../api/paipan.js";
export default defineComponent({
  name: "status-bar",
  components: {
    "my-denglu": denglu,
    tishikuang,
  },
  setup() {
    const router = useRouter();
    let { push } = useRouter();
    const state = reactive({
      dengli: false,
      vipFlag: 1,
      loginNo: "",
      imgsrc: "down",
      shuji: [],
      xinxi: [],
      filePath: process.env.VUE_APP_FILE_PATH + "/",
      menuStatus: 0,
      shijian: 0,
      list: {
        page: 1,
        pageSize: 12,
        name: "",
        personType: null,
      },
      mingren: [],
      userInfo: {
        userName: "",
        birthday: "",
        birthdayStr: "",
        birthday2: "",
        birthday2Str: "",
        sex: 1,
        dunWeiFlag: 0,
        dunWeiFlagVal: 0,
        qiPanFangShi: 1,
        leapFlag: 0,
        agree: false,
        id: "",
      },
    });
    const tiaozhuang = () => {
      bus.emit("denglu-show", {});
    };
    const tiaozhuang1 = (val) => {
      push(val);
    };
    const qu = (val) => {
      let url = router.resolve({
        path: val,
      });
      window.open(url.href);
    };
    const init = () => {
      // SessionStorage.get('user-info').then(res => {
      // if (res) {
      getUserInfo()
        .then((res) => {
          if (res.status == 200 && res.data.status == 1) {
            state.dengli = true;
            state.xinxi = res.data.data;
            // console.log(state.xinxi);
            if (state.xinxi.vipFlag == 1) {
              shijian(state.xinxi.vipOverDate);
            } else {
              state.shijian = state.xinxi.vipFlag;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // }

      // })
      openBookList()
        .then((res) => {
          if (res.status == 200 && res.data.status == 1) {
            state.shuji = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      mingren();
    };
    const shijian = (val) => {
      // console.log(val);
      // 当前时间
      let a = +new Date();
      // 会员到期时间
      let b = +new Date(val);
      // 查看是非过期 正数为 未过期 负数为 已过期
      let c = b - a;
      // console.log(c);
      if (c <= 0) {
        state.shijian = 0;
      } else {
        state.shijian = 1;
      }
      let d = val;
      d = d.split(" ");
      // console.log(d);
      d[0] = d[0].split("-");
      state.xinxi.vipOverDate = d;
    };
    const mouseenter = function () {
      state.imgsrc = "up";
      state.menuStatus = 1;
    };
    const mouseleave = function () {
      state.menuStatus = 0;
      state.imgsrc = "down";
    };
    //退出
    const tuichu = function () {
      logout()
        .then((result) => {
          console.log(result);
          if (result.status == 200 && result.data.status == 1) {
            state.userInfo = null;
            SessionStorage.set("user-info", false);
            push("/");
            location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    //去修改密码
    const xiugai = function () {
      push("/register/find-password");
    };
    // 会员页
    const topayVip = function () {
      push("/geren/huiyuanResult");
    };
    // 充值页
    const gochongzhi = () => {
      push("/geren/chongzhiResult");
    };
    // 订单页
    const godingdan = () => {
      push("/geren/dingdanResult");
    };
    // 个人设置页
    const shezhi = () => {
      push("/geren/shezhiResult");
    };
    // 消息页
    const xiaoxi = () => {
      push("/geren/xiaoxiResult");
    };
    // 余额页
    const yuer = () => {
      push("/geren/yuerResult");
    };

    const gotushu = (val) => {
      state.bookId = val;
      getBookmark({
        bookId: state.bookId,
      })
        .then((res) => {
          //书签信息
          state.bookMarkInfo = res.data.data;
          let bookMarkInfo = res.data.data;
          if (JSON.stringify(bookMarkInfo) === "{}") {
            //没有书签
            router.push({
              path: "/guji/info",
              query: {
                bookId: state.bookId,
              },
            });
          } else {
            router.push({
              path: "/guji/info",
              query: {
                zhangId: res.data.data.id,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const mingren = () => {
      celebrityList(state.list).then((res) => {
        if ((res.status == 200) & (res.data.status == 1)) {
          state.mingren = res.data.data.records;
        }
      });
    };
    const goMingRen = (index) => {
      console.log(index);
      v2Go(index);
      testPaipan(state.userInfo)
        .then((res) => {
          if (res.status == 200 && res.data.status == 1) {
            console.log(res);
            SessionStorage.set("paipan-h5-date", res.data.data);
            SessionStorage.set("query-info", state.userInfo);
            let url = router.resolve({
              path: "/mingreng/result",
            });
            window.open(url.href);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    // 补0
    const bu0 = (val) => {
      return val > 9 ? val : "0" + val;
    };
    // 处理时间
    const v2Go = (index) => {
      state.userInfo.birthday = `${state.mingren[index].birth.year}-${bu0(state.mingren[index].birth.month)}-${bu0(state.mingren[index].birth.day)} ${bu0(state.mingren[index].birth.hour)}:${bu0(state.mingren[index].birth.minute)}:00`;
      state.userInfo.userName = state.mingren[index].name;
      state.userInfo.id = state.mingren[index].id;
    };
    const gomr = () => {
      push("/mingreng/info");
    };
    const pibu = () => {
      push("/danxiang/info");
    };
    const gocundang = () => {
      push("/cundangList");
    };
    init();
    return {
      state: state,
      qu: qu,
      gocundang,
      tiaozhuang: tiaozhuang,
      mouseenter: mouseenter,
      mouseleave: mouseleave,
      tuichu: tuichu,
      xiugai: xiugai,
      topayVip: topayVip,
      gochongzhi: gochongzhi,
      godingdan: godingdan,
      gotushu: gotushu,
      tiaozhuang1: tiaozhuang1,
      shezhi: shezhi,
      xiaoxi: xiaoxi,
      yuer: yuer,
      mingren: mingren,
      goMingRen: goMingRen,
      gomr: gomr,
      pibu: pibu,
    };
  },
});
</script>

<style>
.status-bar {
  height: 72px;
  background: url("../../public/images/header.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 30;
}

.status-bar .daohang {
  padding: 0px 29px;
  color: #999999;
  height: 72px;
  font-weight: 200;
  cursor: pointer;
  line-height: 72px;
  font-size: 18px;
}

.status-bar .denglu {
  width: 198px;
  display: flex;
  justify-content: center;
  position: relative;
}

.status-bar .denglu .aniu {
  width: 77px;
  height: 30px;
  border: 1px solid #e64b39;
  color: #e64b39;
  background: none;
  font-weight: 200;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-bar .denglu .aniu:nth-child(1):hover {
  background-color: #e64b39;
}

.status-bar .daohang:nth-child(1):hover {
  color: #e64b39;
  font-weight: 500;
  font-size: 18px;
}

.status-bar .daohang:nth-child(2):hover {
  color: #e64b39;
  font-weight: 500;
  font-size: 18px;
}

.status-bar .daohang:nth-child(2):hover .danxinag {
  display: flex;
  justify-content: center;
}

.status-bar .daohang .danxinag {
  height: 51px;
  width: 100%;
  background: #fff;
  color: #252525;
  position: absolute;
  left: 0px;
  top: 72px;
  line-height: 51px;
  border-bottom: 1px solid #e64b39;
  font-size: 16px;
  font-weight: 200;
  display: none;
  z-index: 3;
}

.status-bar .daohang .danxinag .dangxianneirong {
  padding: 0px 11px !important;
  height: 51px;
  cursor: pointer;
}

.status-bar .daohang .danxinag .dangxianneirong:hover {
  color: #e64b39;
  font-weight: 500;
  font-size: 16px;
}

.status-bar .daohang:nth-child(3):hover {
  color: #e64b39;
  font-weight: 500;
  font-size: 18px;
}

.status-bar .daohang:nth-child(3):hover .mingren {
  display: flex;
  justify-content: center;
}

.status-bar .daohang .mingren {
  height: 51px;
  width: 100%;
  background: #fff;
  color: #252525;
  position: absolute;
  left: 0px;
  top: 72px;
  line-height: 51px;
  border-bottom: 1px solid #e64b39;
  font-size: 16px;
  font-weight: 200;
  display: none;
  z-index: 3;
}

.status-bar .daohang .mingren .mingrenneirong {
  padding: 0px 11px !important;
  height: 51px;
  cursor: pointer;
}

.status-bar .daohang .mingren .mingrenneirong:hover {
  color: #e64b39;
  font-weight: 500;
}

.status-bar .daohang:nth-child(4):hover {
  color: #e64b39;
  font-weight: 500;
  font-size: 18px;
}

.status-bar .daohang:nth-child(4):hover .paipan {
  display: flex;
  justify-content: center;
}

.status-bar .daohang .paipan {
  height: 51px;
  width: 100%;
  background: #fff;
  color: #252525;
  position: absolute;
  left: 0px;
  top: 72px;
  line-height: 51px;
  border-bottom: 1px solid #e64b39;
  font-size: 16px;
  font-weight: 200;
  display: none;
  z-index: 3;
}

.status-bar .daohang .paipan .paipanneirong {
  padding: 0px 11px !important;
  height: 51px;
  cursor: pointer;
}

.status-bar .daohang .paipan .paipanneirong:hover {
  color: #e64b39;
  font-weight: 500;
}

.status-bar .daohang:nth-child(5):hover {
  color: #e64b39;
  font-weight: 500;
  font-size: 18px;
}

.status-bar .daohang:nth-child(5):hover .guji {
  display: flex;
  justify-content: center;
}

.status-bar .daohang .guji {
  height: 51px;
  width: 100%;
  background: #fff;
  color: #252525;
  position: absolute;
  left: 0px;
  top: 72px;
  line-height: 51px;
  border-bottom: 1px solid #e64b39;
  font-size: 16px;
  font-weight: 200;
  display: none;
  z-index: 3;
}

.status-bar .daohang .guji .gujineirong {
  padding: 0px 11px !important;
  height: 51px;
  cursor: pointer;
}

.status-bar .daohang .guji .gujineirong:hover {
  color: #e64b39;
  font-weight: 500;
}

.status-bar .daohang:nth-child(6):hover {
  color: #e64b39;
  font-weight: 500;
  font-size: 18px;
}

.status-bar .daohang:nth-child(6):hover .chouqian {
  display: flex;
  justify-content: center;
}

.status-bar .daohang .chouqian {
  height: 51px;
  width: 100%;
  background: #fff;
  color: #252525;
  position: absolute;
  left: 0px;
  top: 72px;
  line-height: 51px;
  border-bottom: 1px solid #e64b39;
  font-size: 16px;
  font-weight: 200;
  display: none;
  z-index: 3;
}

.status-bar .daohang .chouqian .chouqianneirong {
  padding: 0px 11px !important;
  height: 51px;
  cursor: pointer;
}

.status-bar .daohang .chouqian .chouqianneirong:hover {
  color: #e64b39;
  font-weight: 500;
}

.status-bar .daohang:nth-child(7):hover {
  color: #e64b39;
  font-weight: 500;
  font-size: 18px;
}

.status-bar .daohang:nth-child(8):hover {
  color: #e64b39;
  font-weight: 500;
  font-size: 18px;
}

.status-bar .barlist {
  color: #ffdd93;
  font-size: 14;
}

.status-bar .barstatus {
  cursor: pointer;
}

.status-bar .barstatus:hover {
  color: #cc3333;
}

.status-bar .menu {
  /* width: 175px; */
  height: 40px;
}

.status-bar .menuitem {
  width: 264px;
  /* height: 443px;	 */
  background-color: #fff;
  color: #000000;
  position: absolute;
  top: 56px;
  right: -30px;
  /* border: 1px solid #eaeaea; */
  z-index: 999;
  padding: 22px 24px;
  box-shadow: 0px 0px 2.6px rgba(0, 0, 0, 0.1), 0px 0px 16.7px rgba(0, 0, 0, 0.048), 0px 0px 32.5px rgba(0, 0, 0, 0.031), 0px 0px 49.1px rgba(0, 0, 0, 0.02), /* 0px 0px 65.8px rgba(0, 0, 0, 0.012), */ 0px 0px 80px rgba(0, 0, 0, 0.006);
}

.status-bar .menuitem .top {
  height: 123px;
  border-bottom: 1px solid #eaeaea;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.status-bar .menuitem .bottom {
  color: #252525;
  font-weight: 200;
  text-align: left;
}

.status-bar .menuitem > div > span:hover {
  color: rgb(229, 83, 75);
}

.status-bar .bianse:hover {
  color: #e64b39;
}

.status-bar .tuichu:active {
  color: #fff !important;
  background-color: #c53423 !important;
}
</style>
